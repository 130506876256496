@import '../../styles/variables';

.main {
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 40px;
}

.state {
  color: var(--color-default);
  text-align: center;

  width: 100%;
  padding: 5px 10px;

  border-radius: 15px;
  transition: background-color 0.6s ease;
}

.button__view {
  color: var(--color-primary);
}

.state__draft {
  background-color: #a0adba;
}

.state__common {
  background-color: #a0adba;
}

.state__delivered {
  background-color: var(--color-primary);
}

.state__risque {
  background-color: #e0ae4f;
}
