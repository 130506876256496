@import '../../styles/shared';

.main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  padding: 34px 40px 40px;

  @media print {
    display: none;
  }

  @include respond-to(tablet) {
    height: 100%;
    flex-grow: 1;
    padding: 24px;
  }

  .ReactTable .rt-td {
    padding: 0px;

    & > span,
    & > div,
    & > ul {
      padding: 0px 16px;
    }
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
  @include respond-to(tablet) {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 24px;
  }
  @media print {
    display: none;
  }

  &__search__container {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    @include respond-to(tablet) {
      width: 100%;
      flex-direction: column;
      position: relative;
    }
  }

  &__searchInput {
    width: 100%;
    font-size: 14px;
    color: var(--color-base);
    border: 1px solid var(--color-base-transparent-40);
    border-radius: 4px;
    background-color: var(--color-default);
    outline: none;
    -webkit-appearance: none;
    padding: 11px 8px;
    @include respond-to(tablet) {
      width: 100%;
      margin-left: 0;
      margin-bottom: 24px;
    }
  }

  &__date {
    display: flex;
    align-items: center;
    margin-right: 100px;
    @include respond-to(tablet) {
      margin-right: 0;
      width: 100%;
      margin-bottom: 32px;
    }
  }

  &__arrowButton {
    width: 40px;
    height: 40px;
  }

  &__datePicker__button {
    display: flex;
    align-items: center;
    width: auto;
    & :first-child {
      order: 1;
      font-size: 18px;
      margin: 0 16px 0 8px;
    }
  }
}

.cell {
  &__item {
    position: relative;
    width: 100%;

    &__text {
      @extend %text-ellipsis;
      padding-right: 24px;
    }

    &__button {
      position: absolute;
      bottom: -100%;
      right: 0;
      transition: 0.1s;

      &:not(:disabled):hover {
        cursor: pointer;
        opacity: 0.8;
      }

      svg {
        width: 24px;
        height: 24px;
      }

      &__primary {
        color: var(--color-primary);
      }

      &__secondary {
        opacity: 0.48;
      }
    }
  }

  &__soldOut {
    width: 100%;
    display: flex;
    justify-content: center;

    &__mark {
      background-color: var(--color-error);
      border-radius: 50%;
      width: 8px;
      height: 8px;
    }
  }
}
