@import '../../styles/shared';

.transactions {
  @extend %scrollbar;

  position: relative;

  display: flex;
  flex-direction: column;

  overflow: auto;
}

.section {
  @extend %scrollbar;

  position: relative;

  display: flex;
  flex-direction: column;

  flex-grow: 1;
  padding: 24px;
  overflow: auto;

  @include respond-to(mobile) {
    padding: 24px 15px;
  }
}

.section__empty {
  color: var(--color-base-transparent-80);
  justify-content: center;
  align-items: center;
}
