.steps__list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .step__item {
    height: 44px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .step__border {
      width: 0;
      height: 100%;
      margin-right: -7px;

      .border__item {
        height: 50%;
        border-right: 2px solid #e7e8ea;

        &.unpassed__border {
          border-right-color: #e7e8ea;
        }

        &.passed__border {
          border-right-color: var(--color-primary-lite);
        }

        &.hidden__border {
          border-right-color: transparent;
        }
      }
    }

    .step__item_label {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;

      &:hover:not(.click__disabled) {
        cursor: pointer;
        .step__dot {
          background-color: var(--color-primary);
        }
        .step__label {
          color: #1e1e1e;
        }
      }

      &.click__disabled:hover {
        cursor: not-allowed;
      }
    }

    .step__dot {
      width: 16px;
      min-width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: var(--color-primary);
    }

    &.step__passed {
      .step__dot {
        background-color: var(--color-primary-lite);
      }
    }

    .step__label {
      padding: 0 16px;
      font-size: 16px;
      min-width: 3em;
      text-align: left;
      color: var(--color-actions);
    }

    &.step__unpassed {
      .step__dot {
        background-color: #e7e8ea;
      }
    }

    &.step__active {
      .step__dot {
        width: 24px;
        min-width: 24px;
        height: 24px;
        border: 4px solid var(--color-default);
        box-shadow: 0 4px 8px 0 var(--color-box-shadow);
        margin-left: -4px;
      }

      .step__label {
        padding: 0 12px;
        color: #1e1e1e;
      }

      .step__item_label:hover {
        cursor: unset;
      }
    }
  }
}
