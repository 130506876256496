.main {
  display: flex;
  flex-direction: column;

  height: 100vh;
  min-height: 100vh;
  min-width: 780px;
  overflow: auto;
  flex-grow: 1;
  padding: 24px 40px;
}
.note {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #f08b31;
  border-radius: 24px;
  color: var(--color-default);
  padding: 0 8px;
  margin-right: 8px;
}
