@import '../../../styles/shared';

.field {
  background-color: var(--color-default);

  font-size: 14px;

  border: 1px solid var(--color-base-transparent-40);
  border-radius: 4px;
  outline: none;

  color: var(--color-base);

  width: 100%;
  height: 40px;
  padding: 12px 8px 12px 8px;
  overflow: hidden;

  transition: $transition-base;

  resize: none;

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    font-size: 14px;
  }

  &:disabled {
    background-color: var(--color-base-transparent-05);
    border-color: var(--color-base-transparent-10);
    color: var(--color-base-transparent-56);
  }
}

.field.field__invalid {
  border-color: var(--color-error);
}

.field__postfix {
  padding-right: 30px;
}

.number__wrapper {
  position: relative;

  display: flex;
  align-items: center;

  &::after {
    position: absolute;
    right: 8px;

    content: attr(data-postfix);

    color: var(--color-base-transparent-56);
    font-size: 16px;
  }
}

.textarea {
  position: relative;
}

.area__limit {
  position: absolute;
  right: 8px;
  bottom: 8px;

  user-select: none;
  pointer-events: none;

  color: var(--color-base-transparent-20);
  font-size: 16px;
}

.value__max {
  color: var(--color-error);
}
