@import '../../../styles/shared';
@import '../../../styles/variables';

$modalHeaderHeight: 35px;

.modal {
  color: var(--color-base);

  :global(.rc-dialog-content) {
    width: 500px;
    height: 660px;

    padding: 18px 0;
  }
  :global(.rc-dialog-body) {
    height: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #747b8b;
  padding: 0 40px 18px;

  border-bottom: 1px solid #e4e7ed;
  &__arrow {
    padding: 0 16px 18px;
  }
}
.body {
  height: calc(100% - #{$modalHeaderHeight});
  padding: 35px 40px;
}
.content {
  height: 100%;
  &_scrolled {
    overflow: auto;
  }
}
.name {
  font-size: 14px;
  color: #2e384d;
}
.label__light {
  font-size: 14px;
  color: var(--color-base-transparent-64);
  margin-bottom: 8px;
}
.text {
  font-size: 14px;
}
.description {
  height: 142px;
  border: 1px solid #bfc5d2;
  border-radius: 4px;
  overflow-y: auto;
  padding: 5px;
  margin-bottom: 19px;
}
.label {
  font-size: 18px;
}
.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  &__button {
    justify-content: space-between;
    margin-top: 45px;
  }
  &__narrow {
    margin-bottom: 0;
  }
  &_wide {
    margin-bottom: 35px;
  }
}
.column {
  display: flex;
  flex-direction: column;
  margin-bottom: 33px;
}

.btn__arrow__block {
  display: flex;
  align-items: center;
}

.error {
  font-size: 14px;
  color: var(--color-status-error);
}
.success {
  font-size: 14px;
  color: var(--color-primary);
}
.configInfo {
  & * {
    line-height: 30px;
    white-space: nowrap;
  }
  & :first-child {
    font-weight: 500;
  }
}
