@import '../../styles/shared';

.layout {
  display: flex;

  min-height: 100vh;

  //noinspection CssInvalidPropertyValue
  @include respond-to(mobile) {
    // fixed problem with 100vh  for web browsers
    height: -webkit-fill-available;
  }
  &__adaptive {
    @include respond-to(tablet) {
      display: flex;
      flex-direction: column;
    }
  }
}

.layout__profile {
  flex-direction: column;
}

.main {
  box-shadow: 0 0 24px 0 rgba(77, 97, 121, 0.24);
}

.modal :global {
  .rc-dialog-content {
    overflow: visible;
  }

  .rc-dialog-body {
    display: flex;

    border-radius: 8px;
    background: linear-gradient(236deg, #f5f5f5, #e6e6e6);
    box-shadow: 0 0 8px 0 #00000029;
  }

  .rc-dialog-close {
    display: none;
  }
}

.btn__close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 99;

  font-size: 14px;
  line-height: 0;
}

.icon__close {
  font-size: 18px;

  &:hover {
    path {
      fill: #69707f;
    }
  }
}

.modal__section:nth-child(1) {
  position: relative;
  transform: translate(10px, -10px);
  z-index: 99;

  flex-shrink: 0;
  height: calc(100% + 20px);

  background-color: var(--color-default);
  box-shadow: 0 0 16px 0 #00000029;
  border-radius: 8px;

  margin-right: 10px;
}

.modal__section:nth-child(2) {
  flex-grow: 1;
  overflow: hidden;
}
