@import '../../../styles/shared';

.content {
  display: flex;
  flex-direction: column;

  padding: 20px 40px;

  min-height: 100vh;

  @include respond-to(mobile) {
    padding: 48px 40px;
  }
}

.logo__wrapper {
  @include respond-to(mobile) {
    text-align: center;

    margin-bottom: 48px;
  }
}

.logo {
  height: 48px;
}

.content__row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-grow: 1;

  @include respond-to(mobile) {
    flex-wrap: wrap;
  }
}

.description {
  width: 535px;

  @include respond-to(laptop) {
    width: 413px;

    margin-bottom: 48px;
  }
}

.title {
  color: #fcc8c2;
  font-size: 288px;
  line-height: 341px;
  text-align: center;

  @include respond-to(laptop) {
    font-size: 196px;
    line-height: 232px;
  }

  @include respond-to(mobile) {
    font-size: 133px;
    line-height: 157px;
  }
}

.explanation {
  color: var(--color-error);
  font-size: 48px;
  line-height: 72px;
  text-align: center;

  margin-bottom: 48px;

  @include respond-to(laptop) {
    font-size: 28px;
    line-height: 52px;

    margin-bottom: 24px;
  }
}

.icon__wrapper {
  @extend %center;
  align-self: stretch;

  width: 638px;

  background: url('../../../assets/circle-bg-full.svg') center center no-repeat;
  background-size: contain;

  @include respond-to(laptop) {
    width: 458px;
  }

  @include respond-to(mobile) {
    width: 100%;
  }
}

.icon {
  color: var(--color-default);

  @include respond-to(laptop) {
    transform: scale(0.7);
  }

  @include respond-to(mobile) {
    transform: scale(0.5);
  }
}
