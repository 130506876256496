.panel {
  position: relative;
  display: flex;

  outline: none;

  flex: 1 0 auto;

  margin-bottom: 40px;
}

.btn__arrow {
  width: 40px;
  align-self: stretch;
}

.notification {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  padding: 16px 24px;

  white-space: nowrap;
  color: var(--color-default);
  font-size: 16px;

  border-radius: 8px;
  background-color: var(--color-primary);
}

.notification__error {
  background-color: var(--color-error);
}

.map__wrapper {
  position: relative;

  flex-grow: 1;
  overflow: hidden;

  transform: translateZ(0);

  :global(.LazyLoad) {
    line-height: 0;
  }

  &::after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    background-color: #eff0ef;
  }
}

// remove extra layer witch disabled events
:global(.ol-overlaycontainer-stopevent) {
  display: none;
}

.map {
  width: 100%;
  height: 100%;
}
