@import 'shared';

.ReactTable {
  font-size: 14px;

  height: 100%;

  border: none;

  .table-header {
    display: flex;
    align-items: center;
  }

  .rt-th[role='columnheader'] {
    outline: none;
  }

  .header-arrow {
    display: none;
  }

  .header-name {
    margin-right: 10px;
  }

  .rt-thead .rt-th.-sort-asc,
  .rt-thead .rt-th.-sort-desc {
    box-shadow: none;

    .header-arrow {
      display: inline-flex;

      font-size: 8px;
    }
  }

  .rt-th.-sort-desc .header-arrow {
    transform: rotate(180deg);
  }

  &.app-table .rt-table {
    overflow: auto;
    height: 100%;

    border-radius: 5px;
    border: 1px solid var(--color-base-transparent-10);
  }

  .rt-table,
  .rt-tbody {
    @extend %scrollbar;
  }

  .rt-thead.-header {
    position: sticky;
    top: 0;
    z-index: 1;

    box-shadow: none;
  }

  .rt-td,
  .rt-thead .rt-th {
    display: flex;
    align-items: center;

    padding: 0 16px;
  }

  .rt-thead {
    background-color: #eff0ef;
    border-bottom: 1px solid var(--color-base-transparent-40);
  }

  .rt-tbody .rt-td {
    color: var(--color-base);

    border: none;
  }

  .rt-thead .rt-th {
    text-align: left;
    color: var(--color-base-transparent-80);
    font-size: 14px;
  }

  .assign-icon {
    fill: var(--color-actions);
  }

  .operations {
    opacity: 0;
  }

  .rt-tr-group {
    flex: 0 0 auto;

    border: none;
    cursor: pointer;
    background-color: var(--color-default);

    &:nth-child(even) {
      background-color: var(--color-base-transparent-05);
    }

    &:hover {
      background-color: var(--color-base-transparent-10);

      .operations {
        opacity: 1;
      }
    }
  }

  .rt-thead,
  .rt-tr-group {
    height: 48px;
  }

  .tags,
  .operations {
    justify-content: center;
  }
}
