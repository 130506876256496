.fallback {
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: #e7e8ea;
}

.icon {
  font-size: 64px;
  color: var(--color-actions);

  margin-bottom: 16px;
}

.description {
  font-size: 16px;
  color: var(--color-base-transparent-80);
}
