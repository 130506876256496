@import '../../../styles/shared';

.content {
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  overflow: auto;
}

.row {
  display: flex;
  flex-shrink: 0;

  padding-bottom: 24px;
}

.search {
  flex-grow: 1;

  margin-left: 24px;
}

.btn__new {
  min-width: 120px;
  padding: 12px 16px;
}

.btn {
  padding: 12px 16px;
}

.icon {
  margin-right: 4px;
}

.table__empty {
  height: 100%;
}
