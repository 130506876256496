.controls__wrapper {
  width: 135px;
  height: 40px;
  margin-top: 16px;

  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.controls__active {
  border-radius: 20px;
  background-color: #f7f7f7;
}

.btn__choose {
  width: 100%;
  padding: 12px;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-width: 90px;
  flex-grow: 1;
  height: 100%;
  padding: 0 8px;

  color: var(--color-default);

  border-radius: 20px;
  background-color: var(--color-primary);
}

.btn__counter {
  line-height: 0;
  font-size: 16px;
  color: var(--color-default);

  &:disabled {
    cursor: default;
  }
}

.amount {
  font-size: 14px;
}

.btn__delete {
  line-height: 0;
  padding: 0 8px;

  font-size: 24px;
  color: var(--color-actions);

  &:disabled {
    cursor: default;
  }
}
