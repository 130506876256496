:root {
  --color-default: #fff;

  // error (customizable)
  --color-error: #f31e04;
  --color-error-rbg: 243, 28, 2;
  --color-error-300: #c42214;
  --color-error-400: #933227;
  --color-status-error: #ea6e5e;

  --color-error-transparent-20: rgba(var(--color-error-rbg), 0.2);

  // primary (customizable)
  --color-primary: #98c11d;
  --color-primary-rgb: 152, 193, 29;
  --color-primary-300: #b3de1c;
  --color-primary-400: #7fa028;
  --color-primary-lite: #cee193;

  --color-primary-transparent-20: rgba(var(--color-primary-rgb), 0.2);

  --color-box-shadow: rgba(0, 0, 0, 0.16);

  // titles
  --color-title-first: #454e4f; // base
  --color-title-second: rgba(69, 78, 79, 0.8); // --color-base-transparent-80

  --color-title-base: #232323;

  // background (customizable)
  --background-base-rgb: 240, 240, 240;

  --background-base-transparent-100: rgba(var(--background-base-rgb), 1);
  --background-base-transparent-48: rgba(var(--background-base-rgb), 0.48);

  // base (customizable)
  --color-base: #454e4f;
  --color-base-rgb: 69, 78, 79;

  // text
  --color-base-transparent-56: rgba(var(--color-base-rgb), 0.56);
  --color-base-transparent-48: rgba(var(--color-base-rgb), 0.48);
  --color-base-transparent-32: rgba(var(--color-base-rgb), 0.32);
  --color-base-transparent-20: rgba(var(--color-base-rgb), 0.2);

  --color-secondary: #b4b4b4;

  // title
  --color-base-transparent-80: rgba(var(--color-base-rgb), 0.8);

  // border
  --color-base-transparent-64: rgba(var(--color-base-rgb), 0.64);
  --color-base-transparent-40: rgba(var(--color-base-rgb), 0.4);
  --color-base-transparent-10: rgba(var(--color-base-rgb), 0.1);

  // zebra stripe
  --color-base-transparent-05: rgba(var(--color-base-rgb), 0.05);

  // divider
  --color-base-transparent-08: rgba(var(--color-base-rgb), 0.08);

  // actions (customizable)
  --color-actions: #8798ad;
  --color-actions-darken: #6c7a8a;
  --color-actions-rgd: 135, 152, 173;

  --color-actions-transparent-48: rgba(var(--color-actions-rgd), 0.48);
  --color-actions-transparent-20: rgba(var(--color-actions-rgd), 0.2);

  // common component colors
  --color-radio-tabs: #69707f;

  // form colors
  --color-form-title-rgb: 46, 56, 77;
  --color-form-title: rgba(var(--color-form-title-rgb), 1); // #2E384D
  --color-form-title-icon: rgba(var(--color-form-title-rgb), 0.48); // #2E384D
  --color-form-label: rgba(51, 51, 51, 1); //#333333
  --color-form-description: rgba(105, 112, 127, 1); //#69707F
  --color-form-palceholder: rgba(194, 197, 199, 1); // #BFC5D2

  --color-border: #e4e7ed;
}

// grid
$col-gutter: 24;
$col-padding: #{$col-gutter / 2}px;
