.spec__values {
  display: flex;
  flex-wrap: wrap;

  margin: 0 -8px -8px 0;
}
.spec__title__group {
  color: var(--color-base-transparent-80);
  font-size: 16px;
  font-weight: 500;

  margin-bottom: 16px;
  margin-top: 24px;
}
.devider {
  font-size: 12px;
  color: var(--color-base-transparent-56);
  margin: 0 8px;
}
.spec__value {
  font-size: 12px;
  color: var(--color-base-transparent-56);
}
.spec__title {
  font-size: 12px;
  color: var(--color-base-transparent-56);
  margin-right: 8px;
  margin-bottom: 8px;
}
