.modal__dataset :global(.rc-dialog-content) {
  overflow: hidden;
  width: 454px;
  padding: 24px 40px;
}

.title {
  margin-bottom: 48px;

  font-size: 16px;
  color: var(--color-base-transparent-80);
}

.label {
  margin-bottom: 8px;

  font-size: 14px;
  line-height: 21px;
  color: var(--color-base-transparent-64);
}

.control {
  margin-bottom: 48px;
}

.btn {
  height: 40px;
  padding: 0 18px;

  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
}
