@import '../../styles/shared';
@import '../../styles/variables';

$header-height: 56px;

.menuMobile__header {
  display: flex;
  align-items: center;
  z-index: 5;
  padding: 0 40px;
  box-shadow: 0 4px 24px #4d617914;
  background-color: var(--color-default);

  @include respond-to(tablet) {
    padding: 12px 24px;
    height: $header-height;
    /* fix header width for Safari */
    min-height: $header-height;
  }

  .menuMobile__header__logo {
    height: 35px;
  }
}

.menuMobile__header__logo__link {
  margin-right: 40px;

  @include respond-to(tablet) {
    margin-right: auto;
  }
}

.menuMobile__header__link {
  @extend %center;
  height: 56px;
  padding: 0 15px;

  justify-content: flex-start;
  font-size: 16px;
  color: var(--color-base-transparent-64);

  &_active {
    color: var(--color-base);
    border-left: 2px solid var(--color-primary);
  }
}

.menuMobile__header__btn__icon {
  margin-right: 24px;

  line-height: 0;
  font-size: 24px;
}

.menuMobile__header__menu {
  z-index: 10;
  position: fixed;
  top: $header-height;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;

  background-color: var(--color-default);

  height: 0;
  transition: height ease 300ms;

  &_show {
    height: 100vh;

    & .menuMobile__header__links {
      visibility: visible;
      opacity: 1;
    }
  }
}

.menuMobile__header__links {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: fit-content;
  padding: 40px;

  visibility: hidden;
  opacity: 0;
  transition: opacity ease 500ms;
}

.menuMobile__header__btn__select {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 100%;
  padding: 0;
  border: none;

  font-size: 24px;

  color: var(--color-actions);
  background-color: var(--color-default);
  box-shadow: none;

  &:hover {
    color: var(--color-actions);
    border: none;
  }
}

.menuMobile__header__actions__list {
  top: 30px;
  right: 0;
  width: 134px;

  & > * {
    font-size: 14px;
    padding: 8px;
  }
}
