@import '../../../../styles/variables';
@import '../../../../styles/shared';

.text {
  padding: 19px 24px;
  text-align: justify;
  color: var(--color-base-transparent-80);
  font-size: 14px;

  p:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.text__bold {
  color: var(--color-base);
  font-weight: 500;
}

.timeSlots {
  height: 385px;
  padding: 24px;

  box-shadow: $box-shadow-line-top;

  overflow: auto;
  @extend %scrollbar;
}

.timeSlot {
  width: 100%;

  padding: 10px 0;
  border-radius: 4px;
  text-align: center;
  color: var(--color-actions);
  font-size: 16px;

  &__selected {
    color: var(--color-default);
    background-color: var(--color-primary);
  }
}

.controls {
  box-shadow: $box-shadow-line-top;
  padding: 24px;
}

.btn {
  padding: 12px 16px;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
}
