@import '../../../../styles/shared';
@import '../../../../styles/variables';

.item {
  width: 100%;
  min-height: 180px;
  background: var(--color-default);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid var(--color-base-transparent-20);
  @include respond-to(tablet) {
    width: 146px;
    min-width: 146px;
    margin-right: 16px;
  }
}
.item__title {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-base);
  word-break: break-word;
  hyphens: auto;
  text-align: center;
}
.item__price {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-base);
  text-align: center;
}
.item__allergens {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 16px;
  padding-left: 8px;
}
.allergen__icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;

  border-radius: 50%;
  background: var(--color-default);
  filter: drop-shadow(-4px 2px 2px var(--color-box-shadow));
}

.item__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  font-size: 12px;
  margin: 8px 0;

  color: var(--color-base);
  word-break: break-word;
}
