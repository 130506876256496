.rc-tooltip {
  border-radius: 8px;
  background: #e4e7ed;
  opacity: 1;
}
.rc-tooltip-inner {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: var(--color-base-transparent-80);
  background: #e4e7ed;
  box-shadow: 0 2px 8px #00000014;
  border: 1px solid #e4e7ed;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  border-top-color: #e4e7ed;
}
