@import '../../../../styles/shared';

.basket {
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-shrink: 0;
  height: 48px;
  padding: 0 16px;

  border-radius: 4px;
  color: var(--color-default);
  background: linear-gradient(167deg, rgba(#000, 0), rgba(#000, 0.2)),
    linear-gradient(167deg, var(--color-primary), var(--color-primary));
  box-shadow: 0 16px 24px rgba(var(--color-primary-rgb), 0.24);

  cursor: pointer;

  @include respond-to(tablet) {
    position: fixed;
    left: 16px;
    right: 16px;
    bottom: 16px;
  }
}

.icon__wrapper {
  display: flex;
  align-items: center;
}

.basket__icon {
  margin-right: 10px;
}

.basket__price {
  margin-right: 20px;
  font-size: 16px;
}
