.layer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.6);
  z-index: 1050;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.container {
  position: fixed;
  height: 300px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1051;
  padding-top: 20px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.title {
  font-size: 15px;
  padding-left: 15px;
  display: inline;
}

.instructions {
  font-size: 15px;
  color: #686868;
  padding-left: 15px;
  padding-top: 20px;
  padding-right: 15px;
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid #b8b8b8;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #b8b8b8;
}

.close {
  font-size: 15px;
  color: #5ea7d5;
  display: inline;
  float: right;
  cursor: pointer;
  padding-right: 15px;
}

.instruction_steps {
  padding-left: 45px;
  padding-top: 15px;
  & li {
    color: #686868;
    padding-bottom: 20px;
    display: flex;

    & i {
      color: black;
    }

    & span {
      padding-right: 20px;
      padding-left: 15px;
      line-height: 1.3;
      display: flex;
      align-items: center;
      color: rgb(74, 74, 74);
    }
  }
}
