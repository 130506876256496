@import '../../../styles/shared';

.content {
  position: relative;

  display: flex;

  min-width: 578px;
  min-height: 462px;
}

.information {
  @extend %center;

  flex-direction: column;

  width: 262px;

  background: #fff url('../../../assets/modal-backgroun.png') top left no-repeat;
  background-size: cover;
}

.iconWrapper {
  margin-bottom: 25px;
}

.infoText {
  color: var(--color-default);
  font-size: 14px;
  text-align: center;
  line-height: 21px;

  padding: 0 25px 0 32px;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
}

.profileTitle {
  color: var(--color-base-transparent-80);
  font-size: 16px;

  margin-bottom: 16px;
}

.userData {
  min-height: 32px;
  margin-bottom: 40px;
}

.profile {
  min-width: 290px;
  padding: 40px;
}

.userName {
  color: var(--color-base);
  font-size: 14px;

  margin-bottom: 6px;
}

.userEmail {
  color: var(--color-base-transparent-80);
  font-size: 12px;
}

.tags {
  display: flex;
  //justify-content: space-between;
  justify-content: center;

  padding: 0 13px;
  margin-bottom: 40px;

  :global(.app-tag) {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 100px;
  }
}

.tag {
  margin-bottom: 10px;
}

.spec__value {
  margin-bottom: 16px;
}

.btn {
  width: 100%;
  height: 40px;

  &:not(:last-of-type) {
    margin-bottom: 40px;
  }

  &:disabled {
    background-color: var(--color-base-transparent-40);
    cursor: default;

    pointer-events: all;
  }
}
