.breadcrumbs {
  color: var(--color-default);
  font-size: 12px;

  :global {
    .link {
      color: var(--color-default);
    }

    .link__to::after {
      content: ' / ';
      white-space: pre;
    }
  }
}

.breadcrumbs__admin {
  font-size: 14px;

  :global(.link) {
    color: var(--color-base-transparent-56);
  }

  & > :last-child {
    color: var(--color-base);
  }
}
