@import '../../../styles/shared';

.modal :global {
  .rc-dialog-content {
    width: 330px;
    height: 463px;

    color: var(--color-base-transparent-80);

    @include respond-to(mobile) {
      width: 290px;
      height: 100%;
    }
  }

  .rc-dialog-body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.title {
  font-size: 16px;
  color: var(--color-base-transparent-80);
  box-shadow: $box-shadow-line-bottom;
  padding: 18px 16px;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding: 40px;
}

.content {
  text-align: center;
  margin-bottom: auto;
}

.icon__wrapper {
  margin-bottom: 40px;
  @include respond-to(mobile) {
    margin-bottom: 30px;
  }
}
.icon__success {
  margin-bottom: 30px;
}
.info {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-base);
  &:last-of-type {
    margin: 16px 0;
  }
}

.description {
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  color: var(--color-base-transparent-80);

  margin-top: 16px;
}

.controls {
  display: flex;

  @include respond-to(mobile) {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.btn {
  padding: 5px 16px;

  @include respond-to(mobile) {
    padding: 12px 16px;
  }

  &:not(:last-of-type) {
    margin-right: 16px;

    @include respond-to(mobile) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
}

.btn__ok {
  width: 80px;
  padding: 12px 16px;
  @include respond-to(mobile) {
    margin-top: 16px;
  }
}
