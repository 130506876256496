@import '../../styles/shared';

.content__tabs {
  @extend %admin-content--tabs;
}

.cell__center {
  justify-content: center;
}

.tabs {
  margin-bottom: 24px;
}

.state {
  color: var(--color-default);
  text-align: center;

  width: 100%;
  padding: 5px 10px;

  border-radius: 15px;
  transition: background-color 0.6s ease;
}

.state__common {
  background-color: #a0adba;
}

.state__bad {
  background-color: #ea6e5e;
}

.state__good {
  background-color: var(--color-primary);
}

.state__risque {
  background-color: #e0ae4f;
}

.dataset__link {
  color: var(--color-primary);

  &:visited {
    color: var(--color-base);
  }
}

.report {
  font-size: 32px;
  color: var(--color-base);
}

.btn__description,
.name {
  @extend %text-ellipsis;
}
.name {
  line-height: 1.5;
}

.btn__switcher {
  width: 16px;
  height: 16px;

  border-radius: 50%;
}
.control__switcher {
  width: 62px;
  & > *:checked + * {
    transform: translateX(195%) !important;
  }
}
.btn__description {
  width: 100%;
  height: 100%;

  color: var(--color-base);
  text-align: left;
}

:global {
  .ReactTable.app-table {
    .rt-table,
    .rt-tbody,
    .rt-td.operations {
      overflow: visible;
    }
  }
}

.action {
  color: var(--color-actions);
  font-size: 14px;
  text-align: left;

  width: 100%;
  padding: 8px 16px;
  box-shadow: 0 1px 0 0 #f3f6f9;

  &:hover {
    background-color: var(--color-base-transparent-05);
  }
}

.action__icon {
  display: flex;
  align-content: center;
  color: var(--color-actions);
  font-size: 24px;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.column__progress,
.column__report {
  justify-content: center;
}
:global(.ReactTable .rt-tr-group .-even),
:global(.ReactTable .rt-tr-group .-odd) {
  height: 48px;
  & > :not(:first-of-type) {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  }
}

:global {
  .ReactTable .rt-tbody {
    & .rt-tr-group .rt-tr-group {
      background-color: var(--color-default);
      border-bottom: none;
    }

    & > .rt-tr-group:nth-child(even) {
      height: 48px;
      background-color: var(--color-base-transparent-05);
    }

    & .rt-tr-group .-even,
    .ReactTable .rt-tr-group .-odd {
      height: 48px;

      & > :not(:first-of-type) {
        border-bottom: solid 1px rgba(0, 0, 0, 0.05);
      }
    }
  }
}

.expander {
  cursor: pointer;
  font-size: 25px;
  padding: 0 !important;
  text-align: center;
  user-select: none;
  flex: 25px 0 0 !important;
  & * {
    fill: var(--color-base-transparent-48);
  }
}
// fixing the resize of expandable column
.deploymentTable {
  :global(.ReactTable .rt-th:first-of-type) {
    flex: 0 !important;
  }
}
