%title {
  font-size: 16px;
  color: var(--color-base-transparent-80);

  padding: 18px 40px;
}

%text-default {
  font-size: 14px;
  color: var(--color-base-transparent-80);
}

%label {
  font-size: 14px;
  color: var(--color-base-transparent-64);

  margin-bottom: 8px;
}
