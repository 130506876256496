@import '../../../styles/shared';

.panel {
  @extend %scrollbar;

  display: flex;
  flex-direction: column;
  grid-row: 2 / 4;

  overflow: auto;
}

.panel__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 16px;
  color: var(--color-base);

  flex-shrink: 0;
  height: 48px;

  margin-right: 22px;

  border-bottom: 2px solid #eff0ef;

  :nth-child(2) {
    color: var(--color-primary);
    font-size: 14px;
  }
}

.controls {
  display: none;
}

.category {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  color: var(--color-actions);

  padding: 0 38px 0 16px;
  min-height: 60px;

  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;

    .code {
      display: none;
    }

    .controls {
      display: inline-flex;
      align-items: center;
    }
  }
}

.category__active {
  background-color: var(--color-primary);

  &:not(:hover),
  &:not(:hover) * {
    color: var(--color-default);
  }
}

.category__titleWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.category__title {
  @extend %text-ellipsis;
  margin-right: 10px;
}

.category__standardArticlesTitle {
  font-size: 12px;
  font-weight: 300;
  color: var(--color-base-transparent-48);
  margin-top: 4px;
}

.list__empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  font-size: 14px;
  color: var(--color-base-transparent-80);
}

.wrapper__empty {
  display: flex;
  flex-direction: column;

  color: var(--color-base-transparent-80);
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}

.icon__plate {
  margin-bottom: 8px;
}

.control {
  font-size: 24px;

  opacity: 0.48;

  padding: 0;

  &:not(:last-of-type) {
    margin-right: 8px;
  }
}
