@import '../../../../styles/shared';
@import '../../../../styles/variables';

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px 10px;
  @include respond-to(mobile) {
    width: 100%;
    border-radius: 0;
    padding: 24px 0 4px 24px;
  }
}
.head {
  display: flex;
  justify-content: space-between;
}
.title__block {
  display: flex;
  flex-wrap: wrap;
  font-size: 24px;
  line-height: 32px;
  color: var(--color-base-transparent-80);
}

.title__recommendation {
  text-align: center;

  min-height: 64px;
  line-height: 32px;
  font-size: 24px;
  margin-bottom: 16px;

  color: var(--color-title-second);

  @include respond-to(tablet) {
    text-align: start;
    min-height: unset;
    margin-bottom: 0;
  }
}
.description__block {
  margin-top: 4px;
}
.title__reset {
  font-size: 14px;
  font-weight: 300;
  color: var(--color-primary);
  cursor: pointer;
}
.title__recommends {
  font-weight: 500;
  margin-right: 16px;
}
.description {
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  color: var(--color-base-transparent-80);
}
.icon__config {
  width: 16px;
  height: 16px;
}
.filters {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;
}
.filters__item {
  background: #98c125;
  border-radius: 4px;
  padding: 5px 8px 5px 16px;
  font-size: 12px;
  color: var(--color-default);
  margin-bottom: 8px;
  &:not(:last-of-type) {
    margin-right: 8px;
  }
}
.icon__close {
  fill: var(--color-default);
  margin-left: 4px;
  cursor: pointer;
}

.button__title {
  display: inline-block;
  margin-right: 8px;
  @include respond-to(mobile) {
    display: none;
  }
}
.items {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  @extend %scrollbar;

  @include respond-to(tablet) {
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-top: 16px;
  }
}
.link {
  font-size: 14px;
  font-weight: 300;
  color: var(--color-primary);
}
.message__noResults {
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  color: var(--color-base-transparent-80);
  text-align: center;
  @include respond-to(tablet) {
    text-align: start;
  }
}
