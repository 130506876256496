@import '../../../../styles/shared';

.category {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.category__row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 8px;

  cursor: pointer;
}

.category__title {
  @extend %text-ellipsis;

  color: var(--color-base);
  font-size: 16px;
}

.submenu__item {
  display: flex;

  color: var(--color-base-transparent-80);
  font-size: 14px;
  line-height: 21px;
  word-break: break-word;

  padding: 8px 0;

  svg {
    margin-top: 5px;
    margin-right: 8px;

    flex-shrink: 0;

    font-size: 10px;

    border-radius: 50%;
    border: 2px solid rgba(191, 197, 210, 0.6);
  }

  polyline {
    display: none;
  }
}
