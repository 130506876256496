@import '../../../../styles/shared';

.section {
  margin-top: 12px;
  border: 1px solid #e1e2e8;
  border-radius: 4px;

  @media print {
    display: none;
  }
}

.header {
  display: flex;
  align-items: center;
  padding: 8px 16px;
}

.title {
  margin-right: 32px;
  font-size: 20px;
  line-height: 30px;
  color: var(--color-title-first);
}

.modeSwitcher {
  display: flex;
  margin-right: auto;
}

.modeRadioButton {
  & input:checked + label {
    background-color: var(--color-radio-tabs);
  }
}

.form {
  height: 1px;
  padding: 0 16px;
  transition: height 0.3s ease-in-out, padding-top 0.3s ease-in-out,
    padding-bottom 0.3s ease-in-out;
}

.form[aria-expanded='true'] {
  height: 94px;
  padding: 16px;

  &.formMenuItems {
    height: 170px;
  }

  .formContent {
    opacity: 1;
    transition: opacity 0.1s ease-in-out 0.2s;
  }
}

.form:not([aria-expanded='true']) {
  .formContent {
    @include visually-hidden;
  }
}

.formContent {
  display: flex;
  gap: 16px;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.formContentColumn {
  flex-direction: column;
}

.formContentLine {
  display: flex;
  gap: 16px;
}

.controlWrapper {
  display: flex;
  flex-direction: column;
}

.controlLabel {
  font-size: 14px;
  line-height: 17px;
  color: var(--color-base-transparent-64);
  margin-bottom: 4px;
}

.datepicker,
.multiSelect,
.select {
  width: 180px;
}

.datepickerButton {
  position: relative;
  @extend %input-datepicker-button;
  padding-left: 8px;
  padding-right: 32px;
}

.datepickerIcon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

// .multiSelect.multiSelectField {
//   width: 220px;
// }

.btnCopy {
  height: 40px;
  padding: 0 24px;
  align-self: flex-end;
}
