@import 'reset';
@import 'variables';
@import 'table';
@import 'shared';
@import 'notifications';

@viewport {
  zoom: 1;
  user-zoom: fixed;
}

::-webkit-scrollbar {
  position: relative;
  right: -8px;

  height: 8px;
  width: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(var(--color-base-rgb), 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(var(--color-base-rgb), 0.3);
}

::-webkit-scrollbar-thumb:active {
  background-color: rgba(var(--color-base-rgb), 0.4);
}

html,
input,
textarea,
button {
  font-family: Rubik, sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

input[type='search']::-webkit-search-cancel-button {
  appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button {
    appearance: none;
  }
}

textarea,
input {
  &::placeholder {
    color: var(--color-base-transparent-20);
  }

  &::-moz-placeholder {
    color: rgba(46, 56, 77, 0.4);
  }

  &:hover {
    border-color: var(--color-base-transparent-64);
  }

  &:focus {
    border-color: var(--color-primary);
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
    -webkit-transition-delay: 9999s;
  }
}

:link {
  text-decoration: none;
}

@media print {
  html,
  body {
    height: 99%;
  }
}
