@import '../../../../styles/variables';
@import '../../../../styles/shared';

.body {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px 0 0 0;
  overflow-y: auto;

  @extend %scrollbar;
}

.text__block {
  display: block;
  padding: 0 40px;
}

.text {
  font-size: 14px;
  line-height: 17px;
  color: var(--color-base-transparent-80);

  & * {
    margin-bottom: 6px;
  }

  &__details {
    margin: 14px 0 40px 0;
  }
}

.text__note {
  text-align: center;
  margin: 40px 0 0;
}

.text__bold {
  color: var(--color-base);
  font-weight: 500;
}

.label {
  font-size: 14px;
  line-height: 21px;
  color: var(--color-actions);
}

.textarea__field {
  height: 100%;
  display: flex;
  flex-direction: column;

  & :last-child {
    height: 100%;
  }
}

.textarea {
  min-height: 100%;
}

.controls {
  display: flex;
  box-shadow: $box-shadow-line-top;
  padding: 24px;
}

.btn {
  padding: 12px 16px;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
}

.form__body {
  min-height: calc(100% - 140px);
  padding: 0 40px 20px 40px;
}

.form__row {
  display: flex;
  width: 100%;
  min-height: 40px;
  margin-bottom: 24px;

  & * {
    width: 100%;
  }
}

.form__row__delivery {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 24px;
  padding: 0 40px 20px 40px;

  & * {
    width: auto;
    align-self: center;
  }
}

.icon {
  width: 40px;
  height: 40px;
  margin-left: -10px;
}

.icon__pickup {
  background-image: url('../../../../assets/modal-busket-order-pickup.png');
}

.icon__delivery {
  background-image: url('../../../../assets/modal-busket-order-delivery.png');
}

.radio {
  padding: 0 16px 0 10px;

  &__active {
    border: 1px solid var(--color-primary);
    border-radius: 4px;
  }
}

.radio__label {
  display: flex;
}

.textarea {
  min-height: 100px;
}

.locations {
  padding: 24px 0;

  box-shadow: $box-shadow-line-top;

  overflow: auto;
  @extend %scrollbar;
}

.location {
  width: 100%;

  padding: 10px 0;
  border-radius: 4px;
  text-align: center;
  color: var(--color-actions);
  font-size: 16px;

  &__selected {
    color: var(--color-default);
    background-color: var(--color-primary);
  }
}

.title {
  padding: 19px 24px;
  text-align: justify;
  color: var(--color-base-transparent-80);
  font-size: 14px;

  p:not(:last-of-type) {
    margin-bottom: 10px;
  }
}
