@import '../../styles/shared';

.btn {
  cursor: pointer;
  outline: none;
  border: none;

  font-size: 14px;

  border-radius: 4px;

  transition: $transition-base;

  &:disabled {
    cursor: not-allowed;
  }

  &__primary {
    color: var(--color-default);
    background-color: var(--color-primary);
    box-shadow: 0 4px 8px var(--color-box-shadow);

    &:hover:enabled {
      background: var(--color-primary-300);
      box-shadow: 0 8px 16px var(--color-box-shadow);
    }

    &:active:enabled {
      background: var(--color-primary-400);
      box-shadow: 0 2px 4px var(--color-box-shadow);
    }

    &:disabled {
      background-color: var(--color-primary-transparent-20);
      box-shadow: none;
    }
  }

  //&__secondary {
  //  color: var(--color-default);
  //  background-color: var(--color-base-transparent-40);
  //
  //  &:hover:enabled {
  //    background-color: #9ea9ac;
  //  }
  //
  //  &:active:enabled {
  //    background-color: #9ea9ac;
  //  }
  //
  //  &:disabled {
  //    background-color: #e8eaeb;
  //  }
  //}

  &__ghost {
    background-color: transparent;
    border: 1px solid var(--color-actions);
    color: var(--color-actions);

    &:hover:enabled {
      background-color: var(--color-actions);
      border-color: var(--color-actions);
      color: var(--color-default);
    }

    &:active:enabled {
      background-color: var(--color-actions-darken);
      border-color: var(--color-actions-darken);
      color: var(--color-default);
    }

    &:disabled {
      background-color: transparent;
      border-color: var(--color-actions-transparent-48);
      color: var(--color-actions-transparent-48);
    }
  }

  &__warning {
    background-color: transparent;
    border: 1px solid var(--color-error);
    color: var(--color-error);

    &:hover:enabled {
      background-color: var(--color-error-300);
      border-color: var(--color-error-300);
      color: var(--color-default);
    }

    &:active:enabled {
      background-color: var(--color-error-400);
      border-color: var(--color-error-400);
      color: var(--color-default);
    }

    &:disabled {
      opacity: 0.2;
    }
  }

  &__text {
    padding: 0;

    background-color: transparent;

    color: var(--color-actions);

    &:disabled {
      opacity: 0.5;
    }
  }
}
