@import '../../../styles/shared';

$header-height: 56px;

.main {
  height: calc(100vh - #{$header-height});
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 24px;

  @include respond-to(tablet) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
}

.transactions__section {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-column: 10 / 13;
  margin-left: -24px;

  padding: 44px 40px;
  font-size: 14px;

  background-color: var(--background-base-transparent-48);
  overflow: auto;

  @include respond-to(tablet) {
    border: none;
    flex-grow: 1;
  }
}

.transactions__title {
  font-size: 16px;
  color: var(--color-title-second);
  border-bottom: 1px solid var(--color-base-transparent-08);
  padding: 12px 0;
}

.transactions {
  padding: 32px 0 0 0;
}
