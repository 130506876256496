.form {
  margin-bottom: 48px;
}
.datepicker {
  width: 156px;
  margin-right: 16px;
}
.datepicker__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 11px 8px 11px 25px;
  border: 1px solid #bfc5d2;
}
.form__fields {
  display: flex;
}
.form__field {
  width: 240px;
  margin-right: 16px;
}
.form__field__small {
  width: 156px;
  &:not(:last-of-type) {
    margin-right: 16px;
  }
}
.form__controls {
  margin-top: 24px;
}
.control {
  padding: 11px;
  &:first-of-type {
    margin-right: 16px;
  }
}
