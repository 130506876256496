@import '../../../styles/shared';

.modal :global {
  .rc-dialog-content {
    border-radius: 0;
    background-color: transparent;
  }

  .rc-dialog-close-x {
    display: none;
  }
}

.content {
  display: flex;
  align-items: center;
}

.column {
  &:nth-child(1) {
    display: flex;
    flex-direction: column;
    z-index: 1;

    flex: 0 0 auto;
    width: 76vw;
    min-width: 720px;
    height: 86vh;
    min-height: 578px;
    padding-bottom: 30px;

    background-color: var(--color-default);
    border-radius: 8px;
    box-shadow: 0 8px 24px 0 #00000029;
  }

  &:nth-child(2) {
    display: flex;
    flex-direction: column;

    background-color: #e6e6e6;
    border-radius: 0 8px 8px 0;

    max-width: 300px;
    min-width: 260px;
    height: 76vh;
    min-height: 530px;
    flex: 0 0 auto;
  }
}

.panel__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 56px;
  padding: 0 24px;
}

.panel__title {
  font-size: 16px;
  color: var(--color-base-transparent-80);
}

.subtag__title {
  margin-bottom: 8px;

  color: var(--color-base-transparent-80);
  font-size: 14px;
}

.btn {
  width: 40px;
  height: 40px;

  font-size: 24px;
  color: var(--color-actions);
  line-height: 0;
}

.btn__add {
  border: 1px solid var(--color-actions);
}

.btn__clear,
.btn__fit {
  border: 1px solid var(--color-actions);
  border-radius: 4px;

  margin-left: auto;

  align-self: stretch;
  padding: 0 16px;
}

.btn__panel {
  height: 40px;
  padding: 0 18px;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 40px;
}

.btn__wrapper,
.zoom {
  display: flex;
  align-items: center;
  align-self: stretch;
}

.btn__wrapper :not(:last-child) {
  margin-right: 16px;
}

.btn__zoom {
  font-size: 24px;
  color: var(--color-actions);
  line-height: 0;
}

.zoom__value {
  color: var(--color-actions);
  font-size: 12px;
}

.draw__active {
  background-color: var(--color-primary);
  box-shadow: 0 0 10px 0 var(--color-primary);
  border: none;

  color: var(--color-default);
}

.field__search {
  font-size: 14px;

  padding: 10px 20px;
}

.tab__list {
  box-shadow: 0 1px 0 0 var(--color-base-transparent-40);
}

.subtags {
  padding: 16px 20px;

  box-shadow: 0 -1px 0 0 var(--color-base-transparent-40);
}

.last__used,
.categories {
  @extend %scrollbar;

  padding: 16px 20px;
  flex-grow: 1;
  overflow: auto;
}

.subtags__list {
  display: flex;
  flex-wrap: wrap;

  margin: 0 -8px -8px 0;
}

.subtag {
  padding: 5px 16px;

  color: var(--color-actions);
  font-size: 12px;

  border: 1px solid currentColor;
  background-color: var(--color-default);
  border-radius: 12px;

  margin: 0 8px 8px 0;
}

.subtag__active {
  color: var(--color-default);

  border: 1px solid var(--color-primary);
  background-color: var(--color-primary);
}

.iconClose {
  font-size: 20px;
}
