@import '../../../styles/shared';
@import '../../../styles/variables';

.modal :global {
  .rc-dialog-body {
    width: 380px;
    height: 600px;
    display: flex;
    flex-direction: column;

    @include respond-to(mobile) {
      width: 300px;
    }
  }
}

.modal__header {
  padding: 20px 40px;
  color: var(--color-base-transparent-80);
  box-shadow: $box-shadow-line-bottom;
}

.modal__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.controls {
  box-shadow: $box-shadow-line-top;
  padding: 20px 40px;
}

.btn {
  padding: 12px 16px;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
}

.btn__add {
  color: var(--color-primary);
}

.cell__wrapper {
  padding: 20px 40px;
  overflow: auto;
  height: 100%;
}

.cell__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.cell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  font-size: 20px;
  background-color: var(--color-primary-400);
  color: var(--color-default);
  border-radius: 8px;
  opacity: 0.6;
  transition: 0.1s;

  &.read__mode {
    opacity: 1;
  }

  &:not(.read__mode):hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &.selected {
    opacity: 1 !important;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }

  &__number {
    position: absolute;
    top: 4px;
    left: 4px;
  }
}
