@import '../../../../styles/shared';
@import '../../../../styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  @include respond-to(tablet) {
    width: 100%;
    margin-right: 0;
  }
}

.week {
  display: flex;
  justify-content: center;
  padding: 9px 0;
  font-size: 32px;
  color: var(--color-title-first);
}

.datePeriod {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-base-transparent-10);
  border-radius: 4px;

  padding: 0 0 19px;
  margin-bottom: 43px;

  @include respond-to(tablet) {
    border: none;
    padding-bottom: 0;
    margin: 0 16px;
  }
}

.datePicker {
  font-size: 14px;
  color: var(--color-base);
}

.calendarWrapper {
  left: -70px;
}

.calendar {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 28px;
  @include respond-to(tablet) {
    margin-top: 32px;
  }
}

.weekday {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 40px;
  width: 40px;
  padding: 12px;
  color: var(--color-actions);
  cursor: pointer;
  @include respond-to(tablet) {
    padding-bottom: 13px;
    justify-content: center;
  }
}
.weekday__current {
  background: var(--color-primary);
  border-radius: 4px;
  @include respond-to(tablet) {
    background: transparent;
    border-bottom: 4px solid var(--color-primary);
    border-radius: 0;
  }
  .day {
    color: var(--color-default);
    @include respond-to(tablet) {
      color: var(--color-actions);
    }
  }
}

.day {
  font-size: 14px;
  line-height: 21px;
  color: var(--color-actions);
}

.date {
  font-size: 14px;
  color: var(--color-base);
  opacity: 0.24;
  @include respond-to(tablet) {
    display: none;
  }
}
.date_selected {
  color: var(--color-default);
  opacity: 1;
}
.date__current {
  font-size: 12px;
  background: var(--color-default);
  border-radius: 12px;
  opacity: 0.24;
  @include respond-to(tablet) {
    display: none;
  }
  &__active {
    color: var(--color-primary);
    padding: 5px 16px;
    opacity: 1;
  }
}
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 18px;

  @include respond-to(tablet) {
    justify-content: space-between;
    align-items: center;
  }
}
.button__config {
  display: none;

  justify-content: space-between;
  width: 119px;
  height: 40px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-actions);
  margin-left: 16px;
  @include respond-to(tablet) {
    position: relative;
    display: flex;
    width: 40px;
    padding: 12px;
  }
}

.quantity__selected {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;

  position: absolute;
  right: -2px;
  top: -1px;
  background: var(--color-primary);
  font-size: 14px;
  color: var(--color-default);
  border-radius: 24px;
}
