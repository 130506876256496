.item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px 0;

  transition: all 0.5s;

  & > :first-child {
    color: var(--color-actions);
    font-size: 12px;
  }

  & > :last-child {
    color: var(--color-base);
    font-size: 14px;
  }
}

.item__clickable {
  cursor: pointer;
}

.item__control {
  display: flex;
  align-items: center;

  flex-shrink: 0;

  & > :first-child {
    margin-right: 8px;
  }
}

.item__clickable {
  cursor: pointer;
}

.item__active {
  border-radius: 12px;
  background-color: var(--color-primary);

  padding: 8px 16px;

  color: var(--color-default);

  & > :nth-child(1n) {
    color: inherit;
  }
}
