@import '../../../styles/shared';

.wrapper {
  max-width: 512px;
  display: flex;
  justify-content: space-between;
  padding: 38px 24px;
  background: #f7f7f7;
  border-radius: 8px;
  margin-bottom: 24px;
  background-image: url('../../../assets/dish-page-big-circle.svg'),
    url('../../../assets/dish-page-small-circle.svg');
  background-position: 100% 0, 40% 100%;
  background-repeat: no-repeat, no-repeat;
}

.main__title {
  font-size: 24px;
  color: var(--color-base-transparent-80);

  margin-bottom: 16px;
}
.description {
  font-size: 14px;
  font-weight: 300;
  color: var(--color-base-transparent-80);
}
.description__block {
  width: 50%;
}
.btn__details {
  width: 76px;
  height: 40px;
  background: var(--color-primary);
  box-shadow: 0 4px 8px #00000029;
  border-radius: 4px;
  font: normal 14px/17px Rubik;
  color: var(--color-default);

  margin-top: 24px;
}
.subscription__block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
}
.subscription__title {
  font-size: 17px;
  color: var(--color-base);
}
.subscription__title__base {
  font-size: 23px;
  color: var(--color-primary);
  text-transform: uppercase;
}
// additional
.additional {
  padding: 24px;
  margin-bottom: 24px;
  overflow: auto;

  @extend %scrollbar;

  border-radius: 8px;
  background-color: #f7f7f7;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }
}

.additional__title {
  color: var(--color-base-transparent-80);
  font-size: 24px;

  margin-bottom: 8px;
}

.additional__description {
  color: var(--color-base-transparent-80);
  font-size: 14px;
  font-weight: lighter;
}
