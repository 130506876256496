@import '../../../styles/shared';

.modal :global {
  .rc-dialog-content {
    min-width: 454px;
  }
}

.title {
  font-size: 16px;
  padding: 24px 40px;

  color: var(--color-base-transparent-80);
}

.form {
  padding: 24px 40px;
}

.form__line {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 14px;
  color: var(--color-base);

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
}

.control {
  margin-bottom: 32px;
}

.controls {
  box-shadow: $box-shadow-line-top;
  padding: 24px 40px;
}

.btn {
  padding: 12px 24px;

  &:not(:first-child) {
    margin-left: 16px;
  }
}

.model {
  background-color: transparent;
}

.form__group {
  padding: 24px 40px;
}

.form__section {
  margin-bottom: 16px;
}

.form__section__title {
  margin-bottom: 18px;
  font-size: 16px;

  color: var(--color-base-transparent-80);
}
