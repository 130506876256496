@import '../../../../styles/shared';

.summary__wrapper {
  display: flex;
  flex-direction: column;

  margin-bottom: 48px;
}

.summary__title {
  font-size: 24px;
  color: var(--color-base);

  margin-bottom: 16px;
}

.summary__list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  max-width: 860px;
}

.summary__item {
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-row-gap: 20px;

  font-size: 14px;
  color: var(--color-base-transparent-64);
}

.item__value {
  font-size: 16px;
  color: var(--color-base);
}

.value__total {
  margin-bottom: 16px;
}

.item__value__divider {
  text-align: center;
}

.value__vatAmount {
  display: grid;
  grid-template-columns: min-content 14px 1fr;
  grid-auto-rows: min-content;
  grid-column-gap: 5px;
  grid-row-gap: 8px;

  margin-right: 10px;
}
