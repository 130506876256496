@import '../../../styles/shared';
@import '../../../styles/variables';

.modal {
  color: var(--color-base);

  :global(.rc-dialog-content) {
    width: 600px;
    padding: 18px 40px;
  }
}

.modal__title {
  font-size: 16px;
  margin-bottom: 24px;
}

.title {
  font-size: 24px;
  margin-bottom: 16px;
}

.param__wrapper {
  margin-bottom: 24px;
}

.params {
  max-height: 140px;

  @extend %scrollbar;
  overflow: auto;
}

.params__item {
  margin-bottom: 8px;
}

.link {
  display: inline-block;
  padding: 12px 16px;
  border: 1px solid var(--color-actions);
  color: var(--color-base-transparent-80);
  border-radius: 4px;

  &:not(:last-of-type) {
    margin-right: 16px;
    margin-bottom: 16px;
  }
}
