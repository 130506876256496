@import '../../../styles/variables';
@import '../../../styles/shared';

.main {
  display: flex;
  flex-direction: column;

  position: relative;

  flex-grow: 1;
  height: 100vh;
}

.task {
  flex-grow: 1;
  padding: 24px 40px;

  display: grid;
  grid-template-columns: 245px 1fr;
  grid-column-gap: 22px;

  color: var(--color-base);
}

.task__types {
  border-radius: 8px;
}

.types__title {
  padding: 14px;
  box-shadow: $box-shadow-line-bottom;

  color: var(--color-base);
}

.item {
  border-radius: 4px;
  font-size: 14px;
  padding: 22px 16px;
  cursor: pointer;
  color: var(--color-actions);

  @extend %text-ellipsis;

  &__active {
    color: var(--color-default);
    background-color: var(--color-primary);
  }
}
