@import '../../../../styles/shared';
@import '../../../../styles/variables';

.specs__panel {
  display: flex;
  flex-direction: column;

  width: 100%;
  @include respond-to(tablet) {
    display: none;
  }
}
.specs {
  @include respond-to(tablet) {
    overflow-y: hidden;
    height: 100%;
  }
}
.title__block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-base-transparent-10);
  padding: 12px 0;
  margin-right: 22px;
  margin-bottom: 32px;
}
.title__filtering {
  font-size: 18px;
  color: var(--color-title-second);
}
.title__reset {
  font-size: 14px;
  font-weight: 300;
  color: var(--color-primary);
  cursor: pointer;
}

.spec {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: var(--color-actions);
  margin-bottom: 24px;
  &:last-of-type {
    order: 2;
  }
  &:nth-of-type(2) {
    order: 3;
  }
  &:nth-of-type(3) {
    order: 4;
  }
}
.specs__list {
  @extend %scrollbar;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  @include respond-to(tablet) {
    padding: 24px;
    height: calc(100% - 55px);
  }
}

.spec__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 24px;
}

.spec__btn__wrapper {
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
  }
}

.spec__btn {
  margin-right: 8px;
}

.filters__list {
  display: none;
  margin-top: 24px;
  margin-left: 8px;
}
.filters__show {
  display: flex;
  flex-wrap: wrap;
}
.quantity__selected {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 24px;
  height: 24px;
  background: var(--color-primary);
  font-size: 14px;
  color: var(--color-default);
  border-radius: 24px;
}
.btn__wrapper {
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(var(--color-base-transparent-40), 0.5);
  padding: 24px;
  @include respond-to(tablet) {
    display: none;
  }
}

.btn {
  width: 100%;
  padding: 12px 0;
}
