@import '../../styles/shared';

.tag {
  @extend %center;

  position: relative;

  width: 80px;
  height: 80px;

  border-radius: 50%;
  background-color: var(--color-base-transparent-40);

  transform: translateZ(0);

  color: var(--color-base-transparent-56);

  &__available {
    color: var(--color-primary);

    background-color: var(--color-primary);
  }

  &__error {
    color: var(--color-error);

    background-color: var(--color-error);
  }

  &__border::before {
    content: '';

    position: absolute;
    top: 20%;
    right: 20%;
    bottom: 20%;
    left: 20%;

    z-index: -1;

    border-radius: 50%;
    background-color: var(--color-default);
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
  }
}

.tagText {
  font-size: 12px;
  text-align: center;
  color: var(--color-base-transparent-56);

  &__available {
    color: var(--color-primary);
  }

  &__error {
    color: var(--color-error);
  }
}
