@import '../../../../styles/shared';
@import '../../shared';

.modal :global {
  .rc-dialog-body {
    width: 650px;
    height: 420px;
  }
}

.form__section {
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.form__title {
  @extend %title;
}

.form__body {
  padding: 0 40px;
  margin: auto 0;
}

.control {
  margin-bottom: 24px;
}

.btn {
  padding: 12px 18px;

  &:not(:first-child) {
    margin-left: 16px;
  }
}

.text {
  font-size: 14px;
  color: var(--color-base-transparent-80);

  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 16px;
}

.company {
  display: flex;
  flex-direction: column;
}

.company__label {
  @extend %label;
  margin-bottom: 20px;
}

.company__name {
  @extend %text-ellipsis;

  font-size: 16px;
  color: var(--color-base);
}

.form__controls {
  box-shadow: $box-shadow-line-top;
  padding: 24px 40px;
}
