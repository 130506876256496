@import '../../styles/variables';
@import '../../styles/shared';

.container {
  margin-bottom: 32px;

  &:not(:last-of-type) {
    margin-right: 24px;
  }
}

.field {
  display: flex;
  margin-bottom: 18px;
  align-items: center;
}

.label {
  color: var(--color-base-transparent-64);
  font-size: 14px;
  margin-right: 16px;
}

.description {
  color: var(--color-form-description);
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
}

.btn {
  color: var(--color-primary);

  &:nth-of-type(1) {
    margin-right: 16px;
  }
}

.selected__message {
  color: var(--color-base-transparent-32);
}

.selected__list {
  display: flex;
  flex-wrap: wrap;
}

.selected__item {
  display: flex;
  @extend %center;

  font-size: 14px;
  background-color: var(--color-primary);
  color: var(--color-default);
  word-break: break-all;
  white-space: pre-wrap;
  border-radius: 4px;

  padding: 5px 10px;
  margin-bottom: 8px;

  &:not(:last-of-type) {
    margin-right: 8px;
  }
}

.subsidizedNotAllItemsTag {
  background: #f08b31;
}

.tag__title {
  margin-right: 10px;
}

.tag__btn {
  line-height: 0;
  color: var(--color-default);
}

.error {
  margin-top: 16px;
}
