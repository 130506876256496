@import '../../styles/shared';

.field {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding-left: 8px;
  padding-right: 32px;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-base);
  border: 1px solid var(--color-base-transparent-40);
  border-radius: 4px;
  outline: none;

  &:hover {
    cursor: pointer;
    border: 1px solid var(--color-base-transparent-64);
  }

  &:focus,
  &[aria-expanded='true'] {
    border-color: var(--color-primary);
  }
}

.icon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);

  path {
    fill: var(--color-actions);
  }
}

.value {
  @extend %text-ellipsis;
}

.placeholder {
  color: var(--color-base-transparent-56);
}
