.filterModal {
  width: 100%;

  .rc-dialog-content {
    overflow: visible;
    width: 100%;
    max-width: 420px;
    margin: 0 10px;
  }

  .filterModal__field {
    label {
      display: block;
      color: var(--color-base-transparent-64);
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 12px;
    }

    .filterModal__select {
      width: 100%;
      height: 100%;
    }

    .select__menu {
      border: 1px solid var(--color-base-transparent-80);
    }

    .select__single-value {
      overflow: visible;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .filterModal__button {
    padding: 12px 16px;
    width: 100%;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}
