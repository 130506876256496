@import '../../../styles/shared';

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  min-width: 900px;
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.body {
  flex-grow: 1;
  display: flex;
  height: calc(100vh - 162px);
  overflow: hidden;

  .steps__wrapper {
    width: 30%;
    padding: 20px 0 20px 100px;
  }

  .form__container {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;

    .form__step_item {
      width: 450px;
      padding: 40px;
      margin-bottom: auto;
      margin-top: auto;

      .step__title {
        color: var(--color-form-title);
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 40px;

        .step__icon {
          margin-left: 10px;
          vertical-align: middle;
          color: var(--color-form-title-icon);
        }
      }
    }
  }
}

.form__group {
  margin-bottom: 40px;
}

.form__divider {
  border-bottom: 1px solid var(--color-border);
}

.form__label,
.form__label label {
  font-size: 18px;
  line-height: 24px;
  color: var(--color-form-label);
}

.subsidy__type {
  label {
    padding-left: 0;
    color: var(--color-form-label) !important;
  }
}
.form__description {
  font-size: 14px;
  line-height: 18px;
  color: var(--color-form-description);
}

.form__group_description {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  color: var(--color-form-label);
}

.radio__description {
  margin-left: 24px;
  margin-bottom: 24px;
}

.form__control {
  margin-top: 16px;
}

.form__row {
  display: flex;
  width: 100%;
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
  &__limits {
    position: relative;
    justify-content: space-between;
  }
  &__disabled:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0.24;
    background: var(--color-default);
  }
}

.switcher {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 32px;
  padding-bottom: 13px;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-form-label);
  & *:first-child {
    margin-bottom: 0;
  }
}

.input__with_label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;

  .input__label {
    font-size: 14px;
    line-height: 17px;
    color: var(--color-form-label);
  }

  .number__input {
    width: 120px;
  }
}

.input__consumption {
  .input__label {
    color: var(--color-form-description);
  }

  .number__input {
    width: 84px;
  }
}

.input__amount {
  margin-top: 40px;

  .input__label {
    font-size: 16px;
  }

  .number__input {
    width: 176px;
  }
}

.controls {
  position: relative;
  display: flex;
  grid-column: span 2;
  padding: 24px 40px 36px 40px;
  box-shadow: $box-shadow-line-top;
  justify-content: flex-end;

  .btn {
    height: 40px;
    padding: 0 16px;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &.btn__next {
      display: flex;
      align-items: center;

      .btn__next_arrow {
        margin-left: 37px;
      }
    }
  }
}
