@import '../../../styles/shared';

.content {
  @extend %scrollbar;

  position: relative;
  z-index: 1;

  flex-grow: 1;
  overflow: auto;
}

.header {
  display: flex;
  align-items: center;

  padding: 34px 0;

  @media print {
    padding: 0;

    margin-bottom: 1em;
  }
}

.warning {
  position: relative;

  display: flex;
  align-items: center;

  color: var(--color-default);
  font-size: 12px;

  padding: 8px 10px;

  border-radius: 6px;
  background-color: var(--color-error);

  &::before {
    content: '';

    position: absolute;
    left: -5px;

    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid var(--color-error);
  }

  @media print {
    display: none;
  }
}

.btn__print {
  margin-left: auto;

  width: 40px;
  height: 40px;

  color: var(--color-base-transparent-80);

  i {
    display: block;
  }

  @media print {
    visibility: hidden;
  }
}

.icon__info {
  margin-right: 4px;
}

.planner__title {
  margin-right: 8px;
  font-size: 20px;
  color: var(--color-base);

  @media print {
    width: 100%;
  }
}

.planner__week {
  position: relative;
  padding: 8px 10px;

  @media print {
    display: none;
  }
}

.planner__print {
  display: none;

  @media print {
    display: flex;
    width: 100%;
    align-items: center;
  }
}

.print__header {
  display: none;
  @media print {
    display: flex;
    flex-direction: column;
    min-width: 60%;
  }
}

.print__title {
  display: flex;
  margin-bottom: 8px;
  width: 100%;
}

.calendar {
  position: relative;

  display: flex;
  align-items: center;

  color: var(--color-base);
  font-size: 14px;

  @media print {
    display: none;
  }
}

.menu__item {
  position: relative;
  display: grid;
  grid-row-gap: 0.5em;

  color: var(--color-base);
  font-size: 12px;
  padding: 12px 0;

  &:not(:last-child) {
    box-shadow: $box-shadow-line-bottom;
  }

  @media print {
    page-break-inside: avoid;
    page-break-after: auto;
    page-break-before: auto;

    &:not(:last-child) {
      margin-bottom: 1em;
      padding: 0 0 0.5em 0;
    }
  }
}

.planner {
  display: table;
  table-layout: fixed;

  width: 100%;

  box-shadow: $box-shadow-line-bottom;

  page-break-inside: auto;
}

.item__header {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.item__title {
  @extend %text-ellipsis;

  font-size: 14px;

  @media print {
    white-space: normal;
    text-overflow: unset;
    font-weight: 500;
    font-size: 13px;
    overflow-wrap: break-word;
    hyphens: auto;
  }
}

.item__amount {
  margin-left: 8px;
  font-size: 12px;
  color: var(--color-secondary);
}

.item__description {
  display: none;
  word-break: break-word;
  hyphens: auto;
  @media print {
    display: block;
    font-size: 11px;
  }
}

.item__data {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 14px;

  & > :nth-child(1) {
    margin-right: 0.5em;
  }

  @media print {
    font-size: 12px;
  }
}

.allergen__icon {
  display: block;

  width: 24px;
  height: 24px;
  @media print {
    width: 18px;
    height: 18px;
  }
}

.item__ingredients {
  display: none;

  @media print {
    font-size: 8px;

    display: grid;
    grid-template-columns: 60% 40%;
    grid-gap: 0.5em;
  }
}

.item__allergens {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  margin-right: -4px;
}

.td.day {
  position: relative;
  padding: 0 12px;

  &:not(:last-child)::after {
    content: '';

    position: absolute;
    top: 12px;
    right: 0;
    bottom: 12px;

    width: 1px;

    background-color: var(--color-base-transparent-40);

    @media print {
      display: none;
    }
  }

  &:not(.day__disabled):not(.day__copy):hover {
    background-color: var(--color-primary-transparent-20);
  }
}

.td {
  &.day__disabled,
  &.day__copy {
    cursor: default;
  }
}

.day__empty {
  vertical-align: middle;
  text-align: center;
}

.day__disabled::before {
  content: '';

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  background-color: rgba(#e6e6e6, 0.65);

  cursor: not-allowed;

  @media print {
    visibility: hidden;
  }
}

.icon__plus {
  opacity: 0.4;

  @media print {
    visibility: hidden;
  }
}

.weekday {
  position: relative;
  color: var(--color-base);

  :first-child {
    margin-right: 10px;

    opacity: 0.5;
  }

  @media print {
    height: 24px;
    padding: 2px 16px;
  }
}

.planner__btn {
  width: 24px;
  height: 24px;

  border-radius: 50%;
  background-color: var(--color-default);

  line-height: 0;
  font-size: 16px;
  color: #454e4f;

  @media print {
    visibility: hidden;
  }
}

.caption,
.tr {
  position: relative;
  display: table-row;

  &:nth-child(odd) {
    background-color: #f7f7f7;
  }

  @media print {
    background-color: var(--color-default);
  }
}

.tr {
  &:nth-child(even) {
    & .menu__item {
      @media print {
        background-color: var(--color-default);
        page-break-inside: avoid;
        page-break-after: auto;
      }
    }
  }

  &:nth-child(odd) {
    & .menu__item {
      @media print {
        background-color: #f7f7f7;
      }
    }
  }
}

.caption {
  display: table-header-group;
  page-break-inside: avoid;
}

.caption__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100%;
}

.line__wrapper {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & * {
    word-break: break-word;
    hyphens: auto;
  }

  @media print {
    color: black;
  }
}

.line__controls > :not(:last-child) {
  margin-right: 8px;
}

.caption .th:first-child {
  border-top-left-radius: 4px;
}

.caption .th:last-child {
  border-top-right-radius: 4px;
}

.caption .th {
  vertical-align: middle;

  padding: 0 14px;
  @media print {
    height: 24px;
    border: 1px solid var(--color-base-transparent-40);
  }
}

.caption .th.th__hidden {
  width: 1px;
  padding: 0;
}

.td {
  padding: 12px;

  cursor: pointer;

  @media print {
    border: 1px solid var(--color-base-transparent-40);
  }
}

.cell__disabled {
  cursor: auto;
}

.th,
.td {
  display: table-cell;

  height: 72px;

  &:first-child {
    position: relative;

    color: var(--color-default);
    font-size: 14px;

    padding: 12px 16px;

    background-color: #454e4f;
    @media print {
      padding: 0 16px;
      color: var(--color-base);
      background: transparent;
    }
  }
}

.tr:nth-child(even) .td:first-child {
  background-color: #566162;
}

.planner__footer {
  display: none;

  @media print {
    padding: 16px 0;
    font-size: 10px;
    //page-break-inside: avoid;

    display: block;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1em;
    grid-row-gap: 2px;
  }
}

.footer__allergen {
  display: inline-block;
  width: 25%;
  //float: inline-end;
  align-items: center;
  page-break-inside: avoid;

  & > :not(:last-child) {
    margin-right: 1em;
  }

  img {
    display: inline;
    float: left;
  }

  span {
    display: inline;
    float: left;
    margin-top: 5px;
  }
}

@media print {
  @page {
    size: auto;
    margin-bottom: 0;
    margin-top: 15px;
  }
  body {
    padding-bottom: 100px;
  }
}

.planner__footer__container {
  display: block;

  @media print {
    display: block;
    //page-break-inside: avoid;
    width: 100%;
    margin-bottom: 130px;
  }
}

.copyright {
  display: none;
  @media print {
    display: block;
    position: fixed;
    bottom: 5px;
    font-size: 10px;
    margin-top: 5px;
  }
}

.watermark {
  display: none;
  @media print {
    width: calc(100% - 80px);
    height: calc(100% - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: fixed;
    left: 0;
    top: 0;

    background: transparent;
    padding: 40px;
  }
}

.watermark__text {
  @media print {
    width: 100%;
    align-self: center;

    color: lightgrey;
    font-size: 75px;
    text-align: center;
    opacity: 0.2;
    transform: rotate(300deg);
    -webkit-transform: rotate(300deg);
  }
}

.catering__logo {
  height: 100px;
  margin-right: 16px;
  z-index: 100;
}

.selectionMask {
  z-index: 1;
  position: absolute;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: background-color $transition-base, border $transition-base;
  cursor: pointer;

  &.active {
    background-color: rgba(var(--color-primary-rgb), 0.1);
    border: 1px solid var(--color-primary);
  }
}

.selectionMaskDay {
  top: 4px;
  left: 4px;
  right: 4px;
}

.selectionMaskWeek {
  inset: 0;
}

.selectionMaskMenuLine {
  inset: 4px;
}

.selectionMaskMenuItem {
  inset: 4px -8px;
}

.warning__message {
  display: none;
  @media print {
    display: block;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
}
