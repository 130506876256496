@import '../../styles/shared';

.wrapper {
  position: relative;
  font-size: inherit;
}

.buttons__list {
  position: absolute;
  top: 45px;
  right: 0;
  z-index: 9999;

  width: 100%;
  border: 1px solid var(--color-actions-transparent-20);
  font-size: inherit;

  overflow: hidden;
  background-color: var(--color-default);
  border-radius: 4px;
  box-shadow: 0 8px 24px #00000014;

  li {
    color: var(--color-base-transparent-80);
    padding: 12px 5px;
    text-align: center;

    transition: $transition-base;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-actions-transparent-20);
    }

    &:hover {
      cursor: pointer;
      background-color: var(--color-base-transparent-05);
    }
  }
}

.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 100%;

  padding: 0 8px 0 16px;

  color: var(--color-actions);
  border: 1px solid var(--color-actions);
  background-color: var(--color-default);
  box-shadow: none;

  &:hover {
    color: var(--color-actions-darken);
    border: 1px solid var(--color-actions-darken);
  }

  &__active {
    color: var(--color-default);
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);

    &:hover {
      color: var(--color-default);
      border: 1px solid var(--color-primary);
    }
  }
}
