@import '../../../styles/shared';
@import '../../../styles/variables';

$modal-top-offset: 24px;
$modal-bottom-offset-portrait: 125px;
$modal-bottom-offset-landscape: 35px;

.modal :global {
  .rc-dialog-content {
    @include respond-to(tablet, portrait) {
      height: calc(100% - #{$modal-bottom-offset-portrait});
    }
    @include respond-to(tablet, landscape) {
      height: calc(100% - #{$modal-bottom-offset-landscape});
    }
  }
  .rc-dialog-body {
    position: relative;

    width: 528px;
    height: 600px;
    @include respond-to(tablet) {
      width: 100vw;
      height: 100%;
    }
    & > * {
      @include respond-to(tablet) {
        width: 100vw;
      }
    }
  }
  @include respond-to(tablet) {
    top: 24px;
    height: calc(100vh - #{$modal-top-offset});
    margin: 0;
    align-items: flex-start;
  }
}
.head {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-base-transparent-10);
}
.reset {
  position: absolute;
  right: 60px;
}

.specs__panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  height: 100%;
  width: 260px;

  @include respond-to(tablet) {
    width: 100%;
  }
}
.specs {
  @include respond-to(tablet) {
    overflow-y: hidden;
    height: 100%;
  }
}

.title {
  color: var(--color-base-transparent-80);
  font-size: 16px;

  padding: 19px 24px;
}
.btn__wrapper {
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(var(--color-base-transparent-40), 0.5);

  padding: 24px;
}

.btn {
  width: 100%;
  padding: 12px 0;
}
