@import '../../../styles/shared';
@import '../../../styles/variables';
@import '../shared';

.modal :global {
  .rc-dialog-body {
    position: relative;
    width: 396px;
    @include respond-to(mobile) {
      width: 100%;
    }
  }
}

.title {
  @extend %title;
  color: var(--color-base-transparent-80);
}
.body {
  height: calc(100% - 140px);
  padding: 40px;
}

.form__controls {
  box-shadow: $box-shadow-line-top;
  border-top: 1px solid var(--color-base-transparent-10);

  padding: 24px 40px;
}

.btn {
  height: 40px;
  padding: 0 16px;

  &:not(:last-child) {
    margin-right: 16px;
  }
}
.form__row {
  display: flex;
  width: 100%;
  &:not(:last-of-type) {
    margin-bottom: 27px;
  }
  & * {
    width: 100%;
  }
}

.validation__block {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.validation__title {
  font-size: 14px;
  line-height: 21px;
  color: var(--color-base-transparent-80);
  margin-bottom: 9px;
}
.validation__message {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: var(--color-base-transparent-80);
  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
  &__invalid {
    color: var(--color-error);
    fill: var(--color-error);
  }
  &__valid {
    color: var(--color-primary);
    fill: var(--color-primary);
  }
}
