@import '../../styles/shared';

.tab__list {
  display: flex;

  flex-shrink: 0;
  overflow: auto;

  box-shadow: $box-shadow-line-bottom;
}

.btn__tab {
  height: 48px;
  min-width: 132px;
  padding: 0 24px;
  flex-shrink: 0;

  border-radius: 0;
}

.tab__active {
  box-shadow: inset 0 -4px 0 0 var(--color-primary);
}

.tab__content {
  display: none;
}

.content__show {
  display: contents;
}
