@import '../../styles/shared';

.control {
  font-size: 24px;

  opacity: 0.48;

  padding: 0;

  &:not(:last-of-type) {
    margin-right: 8px;
  }
}

.wrapper__grid {
  @extend %scrollbar;

  position: relative;

  overflow: auto;
  flex-grow: 1;

  border-radius: 4px;
  border: 1px solid var(--color-base-transparent-40);

  :global {
    .header-name {
      @extend %text-ellipsis;
    }
  }
}

.table__empty {
  height: 100%;
}
