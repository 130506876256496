@import '../../styles/shared';

@mixin fade-effect {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
}

@mixin zoom-effect {
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

@keyframes rcDialogZoomIn {
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes rcDialogZoomOut {
  0% {
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform: scale(0, 0);
  }
}

@keyframes rcDialogFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rcDialogFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

:global {
  .rc-dialog-wrap {
    @extend %scrollbar;

    overflow: auto;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;

    -webkit-overflow-scrolling: touch;

    outline: 0;

    display: flex;
  }

  .rc-dialog-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgb(86, 97, 98);
    background-color: rgba(86, 97, 98, 0.6);
    filter: alpha(opacity=50);

    height: 100%;
    z-index: 1050;

    &-hidden {
      display: none;
    }
  }

  .rc-dialog {
    @extend %center;

    position: relative;

    margin: auto;

    &-fade-enter,
    &-fade-appear {
      @include fade-effect;

      opacity: 0;
      animation-play-state: paused;
    }

    &-fade-leave {
      @include fade-effect;

      animation-play-state: paused;
    }

    &-fade-enter.rc-dialog-fade-enter-active,
    &-fade-appear.rc-dialog-fade-appear-active {
      :local {
        animation-name: rcDialogFadeIn;
      }
      animation-play-state: running;
    }

    &-fade-leave.rc-dialog-fade-leave-active {
      :local {
        animation-name: rcDialogFadeOut;
      }
      animation-play-state: running;
    }

    &-zoom-enter,
    &-zoom-appear {
      @include zoom-effect;

      opacity: 0;

      animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
      animation-play-state: paused;
    }

    &-zoom-leave {
      @include zoom-effect;

      animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
      animation-play-state: paused;
    }

    &-zoom-enter.rc-dialog-zoom-enter-active,
    &-zoom-appear.rc-dialog-zoom-appear-active {
      :local {
        animation-name: rcDialogZoomIn;
      }
      animation-play-state: running;
    }

    &-zoom-leave.rc-dialog-zoom-leave-active {
      :local {
        animation-name: rcDialogZoomOut;
      }
      animation-play-state: running;
    }
  }

  .rc-dialog-content {
    position: relative;

    border-radius: 8px;

    overflow: hidden;

    background-color: var(--color-default);
  }

  .rc-dialog-close {
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;

    font-size: 20px;
    color: var(--color-actions);

    position: absolute;
    top: 14px;
    right: 12px;

    margin: 0;
    padding: 0;
    width: 24px;
    height: 24px;

    opacity: 0.7;
    z-index: 10;

    &:hover {
      opacity: 1;
    }

    &-x:after {
      position: absolute;
      content: '';
      top: 5px;
      left: 11px;
      height: 14px;
      border-left: 2px solid currentColor;
      transform: rotate(45deg);
    }

    &-x:before {
      position: absolute;
      content: '';
      top: 5px;
      left: 11px;
      height: 14px;
      border-left: 2px solid currentColor;
      transform: rotate(-45deg);
    }
  }

  .rc-dialog-header {
    padding: 13px 20px 14px 20px;
    border-radius: 5px 5px 0 0;
    background: var(--color-default);
    color: #666;
    border-bottom: 1px solid #e9e9e9;
  }

  .rc-dialog-footer {
    border-top: 1px solid #e9e9e9;
    padding: 10px 20px;
    text-align: right;
    border-radius: 0 0 5px 5px;
  }
}
