@import '../../../styles/shared';

.form {
  width: 396px;
}

.form__title {
  padding: 18px 40px;
  font-size: 16px;

  color: var(--color-base-transparent-80);
}

.form__body {
  padding: 40px;
}

.description {
  font-size: 14px;
  line-height: 1.5;
  color: var(--color-base-transparent-56);

  margin-bottom: 24px;
}
