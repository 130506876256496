@import '../../../styles/shared';
@import '../../../styles/variables';
@import '../shared';

.modal :global {
  .rc-dialog-body {
    position: relative;
    width: 414px;
    height: 534px;
    @include respond-to(mobile) {
      width: 100%;
    }
  }
}
.modal__success :global {
  .rc-dialog-body {
    height: 415px;
  }
}

.title {
  @extend %title;
}

.body {
  height: calc(100% - 140px);
  border-top: 1px solid #e4e7ed;
  padding: 40px;
  overflow-y: auto;

  @extend %scrollbar;
}

.subtitle {
  font-size: 18px;
  line-height: 20px;
  color: var(--color-base);
  margin-bottom: 24px;
}

.controls {
  border-top: 1px solid #e4e7ed;

  padding: 24px 40px;
}

.btn {
  height: 40px;
  padding: 0 16px;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.field__feedback {
  margin-top: 48px;
}
.textarea {
  min-height: 120px;
  &:focus {
    border: 1px solid var(--color-primary);
  }
}

.success__block {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  padding: 40px;
}

.title__success {
  font-size: 18px;
  line-height: 24px;
  color: var(--color-base);
  text-align: center;
  margin: 30px 0 16px 0;
}
.success__description {
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  color: #69707f;
  text-align: center;
  &:last-of-type {
    margin-bottom: 40px;
  }
}

.order {
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
}

.order__line {
  cursor: pointer;
  display: grid;
  grid-template-columns: 40px 1fr 50px;
  min-height: 30px;
  margin-bottom: 15px;
}

.btn__arrow {
  justify-self: flex-start;
}

.order__number {
  margin-bottom: 4px;

  align-self: center;
  color: var(--color-base);
}

.order__info__line {
  font-size: 12px;
  color: var(--color-actions);

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
}

.order__items {
  display: none;
  flex-direction: column;

  &__expanded {
    display: flex;
  }
}

.order__item {
  display: flex;
  font-size: 12px;
  padding: 12px 0;

  color: var(--color-base);
}

.item__amount {
  margin-right: 20px;
}

.price {
  color: var(--color-base);
}
