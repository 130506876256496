@import '../../../../styles/shared';

.content {
  @extend %scrollbar;

  position: relative;
  z-index: 1;

  flex-grow: 1;
  overflow: auto;
}

.header {
  display: flex;
  align-items: center;

  padding-bottom: 12px;

  @media print {
    padding: 0;

    margin-bottom: 1em;
  }
}

.warning {
  position: relative;

  display: flex;
  align-items: center;

  color: var(--color-default);
  font-size: 12px;

  padding: 8px 10px;

  border-radius: 6px;
  background-color: var(--color-error);

  &::before {
    content: '';

    position: absolute;
    left: -5px;

    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid var(--color-error);
  }

  @media print {
    display: none;
  }
}

.btn__print {
  margin-left: auto;

  width: 40px;
  height: 40px;

  color: var(--color-base-transparent-80);

  i {
    display: block;
  }

  @media print {
    visibility: hidden;
  }
}

.icon__info {
  margin-right: 4px;
}

.planner__title {
  margin-right: 8px;
  font-size: 20px;
  color: var(--color-base);

  @media print {
    width: 100%;
  }
}

.planner__week {
  position: relative;
  padding: 8px 10px;

  @media print {
    display: none;
  }
}

.planner__print {
  display: none;

  @media print {
    display: flex;
    width: 100%;
    align-items: center;
  }
}

.print__header {
  display: none;
  @media print {
    display: flex;
    flex-direction: column;
    min-width: 60%;
  }
}

.print__title {
  display: flex;
  margin-bottom: 8px;
  width: 100%;
}

.calendar {
  position: relative;

  display: flex;
  align-items: center;

  color: var(--color-base);
  font-size: 14px;

  @media print {
    display: none;
  }
}

.planner {
  display: table;
  table-layout: fixed;

  width: 100%;
  height: 1px;

  box-shadow: $box-shadow-line-bottom;

  page-break-inside: auto;
}

.td.day {
  position: relative;

  &:not(:last-child)::after {
    content: '';

    position: absolute;
    top: 12px;
    right: 0;
    bottom: 12px;

    width: 1px;

    background-color: var(--color-base-transparent-40);

    @media print {
      display: none;
    }
  }
}

.day__empty {
  vertical-align: middle;
  text-align: center;
}

.day__disabled::before {
  content: '';

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  background-color: rgba(#e6e6e6, 0.65);

  cursor: not-allowed;

  @media print {
    visibility: hidden;
  }
}

.icon__plus {
  opacity: 0.4;

  @media print {
    visibility: hidden;
  }
}

.weekday {
  position: relative;
  color: var(--color-base);

  :first-child {
    margin-right: 10px;

    opacity: 0.5;
  }

  @media print {
    height: 24px;
    padding: 2px 16px;
  }
}

.planner__btn {
  width: 24px;
  height: 24px;

  border-radius: 50%;
  background-color: var(--color-default);

  line-height: 0;
  font-size: 16px;
  color: #454e4f;

  @media print {
    visibility: hidden;
  }
}

.cell__icon {
  margin-left: 4px;
  cursor: pointer;
  color: var(--color-actions-transparent-48);

  rect {
    fill: transparent;
  }
}

.caption,
.tr {
  position: relative;
  display: table-row;

  &:nth-child(odd) {
    background-color: #f7f7f7;
  }

  @media print {
    background-color: var(--color-default);
  }
}

.tr {
  height: 100%;

  &:nth-child(odd) {
    & .menu__item {
      @media print {
        background-color: #f7f7f7;
      }
    }
  }
}

.caption {
  display: table-header-group;
  page-break-inside: avoid;
}

.caption__wrapper {
  @extend %text-ellipsis;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100%;
}

.line {
  &__cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 18px;
  }

  &__time {
    position: relative;
    height: 100%;

    .time {
      position: absolute;
      right: 0;
      height: 14px;
      line-height: 1;
      margin-top: -0.5em;
      padding-right: 8px;
      user-select: none;

      &::after {
        position: absolute;
        content: '';
        background-color: var(--color-default);
        width: 6px;
        height: 2px;
        top: calc(50% - 1px);
        right: 0;
      }

      &.timestampOnly {
        &::after {
          width: 4px;
          height: 1px;
          opacity: 0.6;
        }
      }
    }
  }

  &__day {
    display: flex;
    position: relative;
    height: 100%;

    .pluItem {
      position: relative;
      z-index: 1;
      width: 10px;
      background-color: var(--color-primary);

      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }
}

.day__disabled {
  .pluItem {
    opacity: 0.5;
  }
}

.line__wrapper {
  height: 250px;

  & * {
    word-break: break-word;
    hyphens: auto;
  }

  @media print {
    color: black;
  }
}

.caption .th:first-child {
  border-top-left-radius: 4px;
}

.caption .th:last-child {
  border-top-right-radius: 4px;
}

.caption .th {
  vertical-align: middle;

  padding: 0 14px;
  @media print {
    height: 24px;
    border: 1px solid var(--color-base-transparent-40);
  }
}

.caption .th.th__hidden {
  width: 1px;
  padding: 0;
}

.td {
  padding: 12px;
  vertical-align: top;

  @media print {
    border: 1px solid var(--color-base-transparent-40);
  }

  &.day__disabled,
  &.day__copy {
    cursor: default;
  }
}

.cell__disabled {
  cursor: auto;
}

.th {
  height: 72px;

  &__cell {
    width: 130px;
  }

  &__timeline {
    width: 100px;
  }
}

.th,
.td {
  display: table-cell;

  &__primary {
    position: relative;

    color: var(--color-default);
    font-size: 14px;

    background-color: #454e4f;
    @media print {
      padding: 0 16px;
      color: var(--color-base);
      background: transparent;
    }
  }
}

.tr:nth-child(even) .td__primary {
  background-color: #566162;
}

.planner__footer {
  display: none;

  @media print {
    padding: 16px 0;
    font-size: 10px;
    //page-break-inside: avoid;

    display: block;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1em;
    grid-row-gap: 2px;
  }
}

@media print {
  @page {
    size: auto;
    margin-bottom: 0;
    margin-top: 15px;
  }
  body {
    padding-bottom: 100px;
  }
}

.planner__footer__container {
  display: block;

  @media print {
    display: block;
    //page-break-inside: avoid;
    width: 100%;
    margin-bottom: 130px;
  }
}

.selectionMask {
  z-index: 1;
  position: absolute;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: background-color $transition-base, border $transition-base;
  cursor: pointer;

  &.active {
    background-color: rgba(var(--color-primary-rgb), 0.1);
    border: 1px solid var(--color-primary);
  }
}

.selectionMaskDay {
  top: 4px;
  left: 4px;
  right: 4px;
}

.selectionMaskWeek {
  inset: 0;
}

.selectionMaskMenuLine {
  inset: 4px;
}

.selectionMaskMenuItem {
  inset: 4px -8px;
}

.warning__message {
  display: none;
  @media print {
    display: block;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
}

.noDataText {
  color: var(--color-base-transparent-32);
  text-align: center;
  margin-top: 128px;
}

.tooltip {
  width: 100px;

  &__wrapper {
    z-index: 1000;
  }

  &__title {
    font-size: 14px;
    text-align: center;

    @extend %text-ellipsis;
  }

  &__time {
    margin-top: 4px;
    font-size: 11px;
    text-align: center;
    color: var(--color-base-transparent-48);
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 4px;
  }

  .btn {
    width: 32px;
    height: 32px;

    font-size: 20px;
    color: var(--color-base-transparent-40);
    line-height: 0;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &:not(:disabled):hover {
      color: var(--color-base-transparent-80);
    }
  }
}
