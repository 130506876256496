.modal__category :global {
  .rc-dialog-content {
    width: 400px;
  }
}

.title {
  padding: 18px 40px;
  font-size: 16px;

  color: var(--color-base-transparent-80);
}

.warning {
  display: flex;
  align-items: center;
  align-self: center;

  font-size: 14px;
  line-height: 21px;
  color: var(--color-base-transparent-80);

  margin-top: 20px;
}

.icon {
  color: var(--color-primary);

  width: 24px;
  height: 24px;

  margin-right: 8px;
}

.control__number {
  flex-shrink: 0;
  margin-bottom: 0;
  margin-right: 16px;
}

.form__body {
  padding: 40px 40px 12px 40px;
}

.form__row {
  margin-bottom: 28px;
  font-size: 14px;
  color: var(--color-base);

  &:nth-child(2) {
    display: flex;
    align-items: flex-end;
  }

  &:nth-child(3),
  &:nth-child(4),
  &:last-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
