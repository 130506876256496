.label {
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  transition: all 0.5s;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
}

.label__name {
  margin-right: 8px;

  padding: 6px 16px;

  font-size: 12px;
  color: var(--color-actions);
  word-break: break-all;

  background-color: #eff0ef;
  border-radius: 12px;
}

.label__active .label__name {
  color: var(--color-default);

  background-color: var(--color-primary);
}

.label__value {
  font-size: 14px;
  color: var(--color-base-transparent-64);
}
