@import '../../styles/shared';
@import '../../styles/variables';

.progress {
  width: 100%;
  margin-right: 8px;

  background-color: var(--color-base-transparent-10);
  border-radius: 10px;
}

.progressBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 4px;

  background-color: var(--color-primary);
  border-radius: 10px;
  transition: width 0.6s ease;
}

.value {
  min-width: 30px;
  font-size: 12px;
}
