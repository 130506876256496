@import '../../../styles/shared';
@import '../../../styles/variables';

.modal {
  color: var(--color-base);

  :global(.rc-dialog-content) {
    width: 500px;
    height: 600px;

    padding: 18px 0;
  }
  :global(.rc-dialog-body) {
    height: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #747b8b;
  padding: 0 40px 18px;

  border-bottom: 1px solid #e4e7ed;
  &__arrow {
    padding: 0 16px 18px;
  }
}
.body {
  height: calc(100% - 43px);
  padding: 24px 40px;
}

.content {
  height: 100%;
  overflow-y: auto;
  &_scrolled {
    overflow-x: auto;
  }
}
.metaInfo {
  & * {
    line-height: 30px;
    white-space: nowrap;
  }
  & :first-child {
    font-weight: 500;
  }
}
.description__label {
  color: var(--color-base-transparent-64);
  margin-bottom: 8px;
}
.description {
  height: 142px;
  border: 1px solid #bfc5d2;
  border-radius: 4px;
  overflow-y: auto;
  padding: 5px;
}
.label {
  font-size: 18px;
}
.row {
  display: flex;
  margin-bottom: 24px;
  &__button {
    justify-content: space-between;
    margin-bottom: 43px;
  }
}
.column {
  display: flex;
  flex-direction: column;
  margin-bottom: 33px;
}

.btn__arrow__block {
  display: flex;
  align-items: center;
}
.count {
  font-size: 14px;
  line-height: 25px;
  color: var(--color-base-transparent-48);
  margin-right: 8px;
}

/* Deployment */

.body__development {
  height: calc(100% - 114px);
  padding: 24px 40px;
}

.deployment {
  display: flex;
  flex-direction: column;
  &:not(:first-of-type) {
    margin-top: 28px;
  }
}
.deployment__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  & > :first-child {
    display: flex;
    align-items: center;
  }
}

.deployment__indicator {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #bec4d1;
  margin-right: 8px;
  &_active {
    background: var(--color-primary);
  }
}
.deployment__title {
  font-size: 14px;
}

.deployment__description {
  font-size: 12px;
  color: var(--color-base-transparent-64);
  margin-left: 16px;
}

.deployment__footer {
  height: 89px;
  border-top: 1px solid #e4e7ed;
  padding: 25px 40px;
}

.btn__trash {
  font-size: 24px;
  margin-left: 28px;
}

.btn__switcher {
  width: 62px;
  & > *:checked + * {
    transform: translateX(195%) !important;
  }
}

.btn_newDeployment {
  height: 100%;
  cursor: pointer;
}
.deployment__controls {
  width: 130px;
}
.deployment__text {
  width: calc(100% - 140px);
}
