@import '../../../../styles/shared';

.modal :global {
  width: 100%;

  .rc-dialog-content {
    width: 100%;
    max-width: 400px;
  }

  .rc-dialog-body {
    display: flex;
    flex-direction: column;
  }
}

.btn {
  padding: 12px 16px;
  width: 100%;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
}
