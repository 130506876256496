@import '../../../../styles/shared';

.body {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  padding: 40px 24px;
}

.icon {
  margin-bottom: 40px;
}

.success__title {
  font-size: 18px;
  color: var(--color-base);

  margin-bottom: 40px;
}

.success__text {
  font-size: 16px;
  line-height: 1.4;
  color: var(--color-base);

  margin-bottom: auto;
}

.btn__ok {
  position: absolute;
  bottom: 40px;
  padding: 12px 30px;
}
