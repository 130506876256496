.actions {
  margin: 12px 0;
}

.btn {
  padding: 12px;
  width: 140px;

  .icon {
    margin-right: 4px;
  }
}
