@import '../../styles/shared';

.main {
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  height: 100vh;
}

.content {
  display: flex;

  flex-grow: 1;
  overflow: auto;
  padding: 24px 40px;
}

.information {
  display: flex;
  flex-direction: column;

  width: 245px;

  margin-right: 16px;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
}

.statistic__list {
  @extend %scrollbar;

  flex-grow: 1;
  overflow: auto;
}

.name {
  display: flex;
  align-items: center;
  justify-content: space-between;

  align-self: stretch;

  border-bottom: 2px solid #eff0ef;
}

.dataset__name {
  @extend %text-ellipsis;

  font-size: 16px;
  color: var(--color-base);
}

.icon__edit {
  font-size: 24px;
  color: var(--color-actions);

  flex-shrink: 0;
}

.labels__item {
  padding: 14px 0;

  box-shadow: $box-shadow-line-bottom;
}

.labels {
  padding: 8px 0;
}
