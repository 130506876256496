.wrapper {
  width: 100%;
}

.label {
  font-size: 14px;
  margin-bottom: 16px;
  color: var(--color-base-transparent-64);
}

.slider__wrapper {
  display: flex;
  align-items: center;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background: #e4e7ed;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--color-default);
  cursor: pointer;

  box-shadow: 0 4px 8px #00000014;
  border: 1px solid var(--color-actions);

  &:hover {
    background-color: var(--color-actions);
  }

  &:active {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}

.range__value {
  font-size: 14px;
  text-align: center;

  padding: 12px 0;
  margin-left: 8px;

  border: 1px solid var(--color-base-transparent-40);
  border-radius: 4px;

  min-width: 75px;
  height: 40px;
}
