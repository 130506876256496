@import '../../../../styles/shared';
@import '../../../../styles/variables';

.modal :global {
  width: 100%;

  .rc-dialog-content {
    width: 100%;
    max-width: 460px;
  }

  .rc-dialog-body {
    display: flex;
    flex-direction: column;
  }
}

.form__field {
  margin-top: 28px;

  &:first-child {
    margin-top: 0;
  }

  &__amount {
    display: flex;
    justify-content: space-between;

    .field__amount {
      width: 100%;
    }

    .btn__edit {
      padding: 12px 20px;
      margin-left: 24px;
    }
  }
}

.btn {
  padding: 12px 16px;
  width: 100%;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
}
