$color-status-common: #a0adba;
$color-status-error: #ea6e5e;
$color-status-finished: #e0ae4f;
$color-status-success: #98c11d;
$color-status-created: #798e5f;
$color-status-queue: #69707f;
$color-status-process: #454e4f;

.state {
  color: var(--color-default);
  text-align: center;

  width: 100%;
  padding: 5px 10px;

  border-radius: 15px;
  transition: background-color 0.6s ease;

  &__common {
    background-color: $color-status-common;
  }

  &__error {
    background-color: $color-status-error;
  }

  &__finished {
    background-color: $color-status-finished;
  }

  &__success {
    background-color: $color-status-success;
  }

  &__created {
    background-color: $color-status-created;
  }

  &__queue {
    background-color: $color-status-queue;
  }

  &__process {
    background-color: $color-status-process;
  }
}
