@import '../../../../styles/shared';

.model {
  @include visually-hidden;
}

.model:disabled + .svg,
.model:checked:disabled + .svg {
  opacity: 0.48;
}

.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &__disabled {
    cursor: not-allowed;
  }

  &:hover:not(.checkbox__disabled) {
    .svg {
      border-color: var(--color-primary);
    }
  }
}

.svg {
  flex-shrink: 0;
  width: 8px;
  height: 8px;

  border-radius: 2px;
  border: 1px solid var(--color-actions);

  transition: all $transition-base;
}

.model:checked + .svg__green {
  background-color: var(--color-primary);
  border: none;

  & .tick {
    visibility: visible;
    stroke: var(--color-default);
  }
}

.model:checked + .svg__red {
  background-color: var(--color-default);
  border: none;
}

.tick {
  fill: none;
  visibility: hidden;
}
