@import '../../../styles/shared';
@import '../ReportPage.module';

.main {
  position: relative;

  display: flex;
  flex-direction: column;

  overflow: auto;
  flex-grow: 1;

  @extend %scrollbar;
}

.form {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  padding: 24px 0;
}

.form__fields {
  display: flex;
  align-items: center;

  margin-bottom: 16px;
}

.form__field {
  width: 240px;
  margin-right: 16px;
}

.form__label {
  display: inline-block;
  height: 17px;
  margin-bottom: 8px;

  font-size: 14px;
  color: var(--color-base-transparent-64);
}

.datepicker {
  @extend %input-datepicker;
}

.datepicker__btn {
  @extend %input-datepicker-button;
}

.form__reportControls {
  display: flex;
  margin-bottom: 16px;
}

.button {
  height: 40px;
  min-width: 135px;
  padding: 0 16px;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.cell__text {
  @extend %text-ellipsis;
}

.table__title {
  font-size: 18px;
  color: var(--color-base);

  margin-bottom: 24px;
}

.total__row {
  min-height: 48px;
  margin-bottom: 8px;

  display: flex;
  align-items: center;

  color: var(--color-default);
  background-color: var(--color-actions-darken);
  border-radius: 4px;
}

.total__value {
  padding: 0 16px;

  &:nth-child(1) {
    margin-right: auto;
  }

  &:nth-child(2) {
    width: 390px;
  }

  &:nth-child(3) {
    width: 170px;
  }
}
