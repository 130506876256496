@import '../../../styles/variables';
@import '../../../styles/shared';

// number of max amount of columns in each breakpoint
$max-col-count: 12;

// calculate column styles
@mixin calc-col($size) {
  $count: 12 / $size;
  padding: 0 $col-padding;
  flex: 0 0 100% / $count;
  max-width: 100% / $count;
}

%col {
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

// generate classes for each breakpoint
@mixin generate-cols() {
  @for $i from 1 through $max-col-count {
    .colSm#{$i} {
      @extend %col;

      @include respond-to(tablet) {
        @include calc-col($i);
      }

      &:not(:last-child) {
        @include respond-to(tablet) {
          padding-bottom: 20px;
        }
      }
    }

    .colMd#{$i} {
      @extend %col;

      @include calc-col($i);
    }
  }
}

@include generate-cols;

.colSm0 {
  @include respond-to(tablet) {
    display: none;
  }
}

.colMd0 {
  display: none;
}
