.modal :global(.rc-dialog) {
  &-content {
    width: 334px;
    padding: 22px 40px 40px 40px;
  }

  &-close {
    color: var(--color-actions);
  }
}

.title {
  display: flex;
  align-items: center;

  color: var(--color-base-transparent-80);
  font-size: 16px;

  margin-bottom: 42px;
}

.icon {
  font-size: 24px;

  margin-right: 8px;
}

.description {
  font-size: 16px;
  color: var(--color-base-transparent-80);
  line-height: 21px;

  margin-bottom: 48px;
}

.btn {
  padding: 12px 18px;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
}
