@import '../../styles/shared';

.wrapper {
  display: flex;
  flex-direction: column;
}
.main {
  display: grid;
  grid-template-columns: 334px 1fr;
  grid-template-rows: 44px 1fr;
  grid-row-gap: 22px;

  height: 100vh;
  flex-grow: 1;
  padding: 24px 40px 40px 40px;
  min-width: 780px;

  .products .product__name {
    white-space: normal;
  }

  .products .product__priceBrutto {
    white-space: normal;
  }

  .products .product__description {
    white-space: normal;
  }
  .products {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.controls {
  display: flex;

  margin-right: 10px;
}

.btn {
  width: 50%;
  padding: 13px;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.cell__text {
  @extend %text-ellipsis;
}

.btn__description {
  @extend %text-ellipsis;
}

.btn__description {
  width: 100%;
  height: 100%;

  color: var(--color-base);
  text-align: left;
}
.pagination {
  width: 100%;
  align-items: flex-end;
  margin-top: -22px;
}
.header__switch {
  display: flex;
  align-items: center;
  & *:first-child {
    margin-right: 8px;
  }
}

.control {
  font-size: 24px;

  opacity: 0.48;

  padding: 0;

  &:not(:last-of-type) {
    margin-right: 8px;
  }
}
