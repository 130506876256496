@import '../../../styles/shared';
@import '../../../styles/variables';

.modal {
  color: var(--color-base);

  :global(.rc-dialog-content) {
    width: 500px;
    height: 590px;

    padding: 18px 0;
  }
  :global(.rc-dialog-body) {
    height: 100%;
  }
  .textarea {
    height: 177px;
  }
}

.header {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #747b8b;
  padding: 0 40px 18px;

  border-bottom: 1px solid #e4e7ed;
  &__arrow {
    padding: 0 16px 18px;
  }
}
.form {
  height: calc(100% - 43px);
}
.body {
  padding: 24px 40px 0 40px;
}
.label {
  font-size: 14px;
  color: var(--color-base-transparent-64);
  margin-bottom: 20px;
}
.text {
  font-size: 14px;
  color: #2e384d;
}

.row {
  display: flex;
  align-items: center;
  &__button {
    justify-content: space-between;
    & *:first-child {
      color: var(--color-base-transparent-48);
      margin-bottom: 0;
    }
  }
}
.column {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
}

.btn__arrow__block {
  display: flex;
  align-items: center;
}

.footer {
  height: 89px;
  border-top: 1px solid #e4e7ed;
  padding: 25px 40px;
}
.btn {
  height: 100%;
  &:first-of-type {
    margin-right: 16px;
  }
}

.servingTag {
  width: 100%;
  padding: 10px 0;
  border-radius: 4px;
  text-align: center;
  color: var(--color-base);
  font-size: 16px;
  &__anable {
    &:hover {
      background-color: var(--color-actions);
      color: var(--color-default);
    }
  }
}
