@import '../../../styles/shared';
@import '../ReportPage.module';

.reportTable {
  :global {
    .rt-thead {
      position: sticky;
      top: 56px !important;
    }

    .app-table,
    .rt-table {
      overflow: visible !important;
    }
  }

  position: static;
  width: 100%;
}

.main {
  position: relative;

  display: flex;
  flex-direction: column;

  overflow: auto;
  flex-grow: 1;
}

.form {
  display: flex;
  align-items: flex-end;

  padding: 24px 0;
}

.form__field {
  width: 240px;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.btn {
  height: 40px;
}

.form__label {
  display: inline-block;
  height: 17px;
  margin-bottom: 8px;

  font-size: 14px;
  color: var(--color-base-transparent-64);
}

.datepicker {
  @extend %input-datepicker;
}

.datepicker__btn {
  @extend %input-datepicker-button;
}

.button {
  height: 40px;
  min-width: 135px;
  padding: 0 16px;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.table__wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  overflow: auto;
  margin-bottom: 48px;

  border: 1px solid var(--color-base-transparent-40);
  border-radius: 4px;

  @extend %scrollbar;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.table {
  overflow: visible;
  border: none;
  margin-top: 8px;
}

.total__row {
  @extend %table-total-row;
}

.total__value {
  padding: 0 16px;

  &:nth-child(1) {
    width: 160px;
  }

  &:nth-child(2) {
    width: 200px;
  }

  &:nth-child(3) {
    width: 200px;
  }

  &:nth-child(4) {
    width: 200px;
  }

  &:nth-child(5) {
    width: 200px;
  }

  &:nth-child(6) {
    width: 200px;
  }

  &:nth-child(7) {
    width: 200px;
  }

  &:nth-child(8) {
    width: 200px;
  }
}

.btn__arrow {
  padding-right: 18px;
}

.cell__text {
  @extend %text-ellipsis;
}
