@import '../../../styles/shared';

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.row {
  display: flex;

  padding: 24px 0;
}

.refundedHeader {
  text-align: center;
  white-space: normal;
}

.model__search {
  flex-grow: 1;

  margin-left: 24px;
}

.btn {
  min-width: 120px;
  padding: 12px 16px;
}

.icon {
  margin-right: 4px;
}

.table__empty {
  height: 100%;
}

.cell__text {
  @extend %text-ellipsis;
}
