.modal :global(.rc-dialog-content) {
  width: 475px;
  padding: 40px;

  border-radius: 10px;

  overflow: hidden;
}

.text {
  margin-top: 25px;

  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: #232323;
}

.buttons {
  display: flex;
  justify-content: flex-end;

  margin-top: 30px;
}

.btn {
  padding: 1em 2em;

  font-size: 12px;
  font-weight: bold;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
}
