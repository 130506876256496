@import '../../../styles/shared';

.modal :global {
  .rc-dialog-content {
    width: 330px;
  }
}

.body {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;

  max-height: 500px;
  padding: 40px 40px 0;
}

.title {
  color: var(--color-base-transparent-80);
  padding: 18px 40px;
  box-shadow: $box-shadow-line-bottom;
}

.refill__values {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.refill__value {
  &:not(:last-of-type) {
    margin-right: 16px;
  }

  label {
    width: 70px;
    padding: 12px 0;

    border: 1px solid var(--color-actions);

    color: var(--color-actions);
    border-radius: 4px;
    transition: $transition-base;

    &:hover {
      background-color: var(--color-actions);
      border-color: var(--color-actions);
      color: var(--color-default);
    }
  }

  input:checked + label {
    color: var(--color-default);
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
}

.manualInput {
  position: relative;
  display: flex;
  justify-content: space-between;

  margin-bottom: 35px;
}

.btn__other {
  z-index: 1;
  width: 100%;
  height: 40px;

  &__active {
    width: 85px;
    border: 1px solid var(--color-primary);

    &:hover {
      border-color: var(--color-primary-300);
    }
  }
}

.field {
  position: absolute;
  right: 0;
  margin-left: 15px;

  opacity: 0;

  &__active {
    opacity: 1;
  }

  input {
    width: 147px;
  }
}

.text {
  font-size: 14px;
  color: var(--color-base-transparent-64);
  text-align: justify;
  margin-bottom: 20px;
}

.methods__title {
  padding: 12px 0;

  color: var(--color-base-transparent-80);
  box-shadow: $box-shadow-line-bottom;
}

.methods__list {
  max-height: 250px;
  padding: 10px 0 10px 15px;

  overflow: auto;
  @extend %scrollbar;
}

.methods__item {
  padding: 4px 0;
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  * {
    transition: $transition-base;
  }
}

.icon {
  justify-self: flex-end;
  filter: grayscale(1);
  opacity: 0.5;

  &__active {
    filter: grayscale(0);
    opacity: 1;
  }
}

.notification {
  margin: 0 -40px;
  padding: 15px 40px;
  font-size: 14px;
  color: var(--color-default);
  background-color: #f08b31;
}

.link {
  margin: 24px 0;
  font-size: 14px;
  text-align: justify;
  text-decoration: underline !important;
  color: var(--color-base-transparent-64);
  cursor: pointer;
  transition: $transition-base;

  &:hover {
    color: var(--color-base-transparent-80);
  }
}
