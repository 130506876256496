.popup {
  position: fixed;
  right: 24px;
  bottom: 24px;

  width: 48px;
  height: 48px;

  border-radius: 50%;

  z-index: 10;
}

.icon__wrapper {
  margin-bottom: 16px;
}

.btn {
  width: 100%;
  height: 100%;

  border-radius: 50%;

  background-color: var(--color-error);
}

.message {
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: center;
  color: var(--color-default);
  font-size: 16px;
  line-height: 24px;

  border-radius: 40px 40px 8px 40px;
  background-color: var(--color-error);

  position: absolute;
  bottom: 100%;
  right: 100%;

  width: 174px;
  min-height: 189px;

  padding: 13px 23px;

  animation: show 0.5s;
  animation-fill-mode: forwards;

  transform-origin: bottom right;
  transform: scale(0);
  will-change: transform;
}

@keyframes show {
  100% {
    transform: scale(1);
  }
}
