.dropzone {
  padding: 24px 16px;

  text-align: center;

  outline: none;
  border-radius: 4px;
  border: 1px dashed #8798ad;
}

.dropzone__title {
  color: var(--color-base-transparent-80);
  font-size: 14px;

  margin-bottom: 8px;
}

.dropzone__description {
  color: var(--color-base-transparent-56);
  font-size: 14px;
  line-height: 18px;
}

.here {
  color: var(--color-primary);
  text-decoration: underline;
}
