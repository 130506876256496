$transition-time: 0.5s;

.track {
  width: 44px;
  height: 24px;
  padding: 2px;

  display: inline-block;

  position: relative;

  border-radius: 20px;
  background-color: var(--color-base-transparent-40);
  cursor: pointer;

  transition: $transition-time background-color;
  will-change: background-color;
  opacity: 1;
  &__disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.track__checked {
  background-color: var(--color-primary);
}

.input {
  position: absolute;
  z-index: -1;

  width: 1px;
  height: 1px;
}

.thumb {
  box-shadow: 0 2px 4px 0 #00000029;
  background-color: var(--color-default);
  border-radius: 50%;

  display: inline-block;

  width: 20px;
  height: 20px;

  transform: translateX(0);
  transition: $transition-time all;
}

.input:checked + .thumb {
  transform: translateX(100%);
}

.thumb__middle {
  transform: translateX(50%);
}
.title {
  position: absolute;
  top: 5px;
  left: 8px;
  font-size: 12px;
  line-height: 14px;
  color: var(--color-default);
  &__off {
    right: 8px;
    left: auto;
  }
}
