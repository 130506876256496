.main {
  // display: flex;
  // flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  min-width: 780px;
  overflow: auto;
  flex-grow: 1;
  padding: 24px 40px;
}
