.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 500;
  color: var(--color-base);
}
