@import '../../styles/shared';

.main {
  position: relative;

  display: flex;
  flex-direction: column;

  flex-grow: 1;
  height: 100vh;
  min-width: 900px;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;

  overflow: auto;
  flex-grow: 1;

  .category__field {
    margin-bottom: 8px;
  }
}

// controls
.controls {
  position: relative;
  display: flex;
  grid-column: span 2;
  padding: 24px 40px 36px 40px;
  box-shadow: $box-shadow-line-top;
}

.btn {
  height: 40px;
  padding: 0 16px;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

// basic
.basic {
  padding: 24px 40px 40px 40px;
  overflow: auto;

  @extend %scrollbar;
}

.form__group:not(:last-child) {
  margin-bottom: 24px;
}
.spec_block {
  display: flex;
  flex-direction: column;
}

.group__title {
  font-size: 14px;
  color: var(--color-base-transparent-56);

  margin-bottom: 16px;
}
.group__title__allergen {
  font-size: 14px;
  color: var(--color-base-transparent-56);

  margin-bottom: 16px;
}

.basic__title {
  font-size: 24px;
  color: var(--color-base-transparent-80);

  margin-bottom: 24px;
}

.dish__category {
  font-size: 14px;
  color: var(--color-base-transparent-64);
  font-weight: lighter;
}

.category__btn {
  margin-left: 4px;

  color: var(--color-primary);
}
.wrapper {
  overflow: auto;
  padding: 24px 40px 40px 40px;
}
.explanation__text {
  position: absolute;
  bottom: 10px;
  font-size: 12px;
  color: var(--color-base-transparent-80);
}
.switcher {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 25px;
  font-size: 14px;
  color: var(--color-base-transparent-80);

  & * {
    margin-bottom: 0;
    align-self: center;
  }
}
.label {
  display: block;

  color: var(--color-base-transparent-80);
  font-size: 14px;
  font-weight: normal;

  height: 17px;
  &__disabled {
    color: var(--color-base-transparent-40);
  }
}

.textarea {
  min-height: 64px;
}

.vat_form__rates_container {
  display: flex;
}

.vat_form__grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 1fr));
}

.vat__rates__column {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  margin-right: 48px;
}

.vat__value_switcher_container {
  text-align: center;
  margin-bottom: 8px;
}

.vat__value_switcher {
  text-align: initial;
}

.vat__value_hint {
  text-align: center;
}

.tooltip {
  margin-left: 10px;
  vertical-align: middle;
  color: var(--color-form-title-icon);
}
