.items {
  font-size: 14px;
  line-height: 1.1;
}

.item {
  display: flex;
  padding: 0.25em 0;
}

.item__amount {
  padding: 0 0.5em;
  margin-right: 0.5em;
}

.recentOrder {
  background-color: var(--color-status-error);
  color: var(--color-default);
  width: 100%;
  height: 100%;
  align-items: center;
  display: grid;
}
