@import '../../../../styles/shared';

.task__info {
  display: flex;
  align-items: center;
  padding: 24px;
  font-size: 14px;
}

.icon__info {
  width: 32px;
  height: 32px;

  margin-right: 16px;
}

.task__form {
  display: flex;
  flex-direction: column;

  min-width: 550px;

  border: 1px solid var(--color-base-transparent-10);
  border-radius: 8px;
}

.form__body {
  flex-grow: 1;
  padding: 24px;
}

.form__options__top__aligned {
  & > div:first-child {
    align-items: normal;
    & > div {
      max-width: 460px;
    }
  }
}

.control {
  width: 230px;

  &__medium {
    width: 340px;
  }

  &__wide {
    width: 460px;
  }
}

.control__description {
  margin-bottom: 40px;
}

.control__option {
  margin-bottom: 24px;

  &:not(:last-of-type) {
    margin-right: 24px;
  }
}

.title {
  font-size: 24px;
  color: var(--color-base-transparent-80);

  margin-bottom: 24px;
}

.form__options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
}

.controls {
  box-shadow: $box-shadow-line-top;
  padding: 24px;
}

.btn {
  padding: 12px 16px;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
}

.remap__labels_title {
  font-size: 14px;
  color: var(--color-base-transparent-64);
  margin-top: 14px;
}

.remap__labels_value {
  margin-left: 20px;
  margin-top: 14px;
}
