@import '../../../styles/shared';

.search {
  position: absolute;
  left: 1020px;
  top: 7px;
  right: 40px;
}

.tab__content__container {
  display: flex;
  height: auto;
  overflow: auto;
  flex-grow: 1;
}
