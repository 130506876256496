@import '../../../styles/shared';

.help__modal {
  & .rc-dialog-close {
    color: var(--color-default);

    opacity: 1;
  }

  .help__link {
    font-size: 14px;
    color: var(--color-primary);
    text-decoration: underline;
    white-space: nowrap;

    &:not(:last-of-type) {
      margin-right: 16px;
    }
  }
}

.help__content {
  min-height: 400px;
  width: 410px;
  padding: 24px 40px;

  color: var(--color-base-transparent-80);
  background: url('../../../assets/modal-bg-top.png') 101% 0 no-repeat;

  @include respond-to(mobile) {
    width: 345px;
    padding: 24px 15px;
  }
}

.help__info {
  h3 {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }

  div {
    margin-bottom: 16px;
  }

  span {
    margin-right: 5px;
    font-size: 14px;
  }

  a {
    font-size: 14px;
    color: var(--color-primary);
    text-decoration: underline;
    white-space: nowrap;
  }

  b {
    font-weight: 500;
  }

  & > div {
    margin-bottom: 28px;
  }
}

.help__title {
  margin-bottom: 24px;

  span {
    font-size: 16px;
    line-height: 24px;
  }
}

.help__links_wrapper {
  display: flex;
  justify-content: center;

  padding-top: 15px;
}
