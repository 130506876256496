@import '../../../../styles/shared';

.radio {
  position: relative;
}

.label {
  @extend %center;
  width: 100%;
  height: 100%;
  padding: 3px 16px;
  font-size: 14px;
  color: var(--color-actions);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.15s ease-in-out;
}

.label__circleInput {
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  position: relative;

  &:before {
    content: ' ';
    display: inline-block;
    position: relative;
    flex-shrink: 0;
    margin: 0 16px 0 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid var(--color-actions);
    background-color: transparent;
  }
}

.radio__circle {
  height: 40px;
}

.input {
  @include visually-hidden;

  &:checked + label {
    color: var(--color-default);
    background-color: var(--color-primary);
  }
}

.input__circle {
  &:checked + label {
    color: var(--color-actions-darken);
    background-color: transparent;
  }

  &:checked + label:before {
    border-color: var(--color-primary);
    background-color: var(--color-primary);
  }
}
