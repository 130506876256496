@import '../../../styles/shared';
@import '../shared';

.modal :global {
  .rc-dialog-content {
    width: 450px;
  }
}

.title {
  @extend %title;
}

.description {
  @extend %text-default;
  font-weight: lighter;
  text-align: center;

  padding: 24px 40px 16px 40px;

  box-shadow: $box-shadow-line-bottom;
}

.datasets {
  height: 320px;
  overflow: auto;
  padding: 0 16px;

  box-shadow: $box-shadow-line-bottom;
}

.btn__wrapper {
  display: flex;
  align-items: center;

  padding: 24px 40px;
}

.btn {
  padding: 12px 16px;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.btn__dataset {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @extend %text-default;

  padding: 11px 16px;
  width: 100%;

  & :nth-child(1) {
    @extend %text-ellipsis;
  }
}

.btn__active {
  background-color: var(--color-primary);
  border-radius: 4px;

  color: var(--color-default);
}
