@import '../../../../styles/shared';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    margin-bottom: 54px;
  }

  @include respond-to(tablet) {
    position: static;

    &:not(:last-of-type) {
      margin-bottom: 40px;
    }
  }
}

.header {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  margin-bottom: 24px;

  border-bottom: 1px solid var(--color-base-transparent-08);
}

.title {
  margin-bottom: 24px;
  margin-right: 20px;

  color: var(--color-title-second);
  font-size: 18px;
}
