@import '../../../styles/shared';

.modal :global {
  .rc-dialog-content {
    width: 454px;
  }
}

.title {
  color: var(--color-base-transparent-80);
  font-size: 16px;

  padding: 18px 40px;

  word-break: break-word;
}

.description {
  color: var(--color-base-transparent-80);
  font-size: 14px;
  font-weight: lighter;

  padding: 24px 40px;
}

.field__label {
  display: inline-block;
  color: var(--color-base-transparent-64);
  font-size: 14px;

  margin-bottom: 8px;
}

.field__comment {
  padding: 0 40px 22px 40px;

  box-shadow: $box-shadow-line-bottom;
}

.form__control {
  margin: 0;
}

.btn__wrapper {
  display: flex;
  align-items: center;

  padding: 22px 40px;
}

.btn {
  padding: 12px 16px;

  &:not(:last-child) {
    margin-right: 16px;
  }
}
