@import '../../../styles/shared';

.name {
  @extend %text-ellipsis;
}

.row {
  display: flex;

  padding: 24px 0;
}

.model__search {
  flex-grow: 1;

  margin-left: 24px;
}

.btn {
  min-width: 120px;
  padding: 12px 16px;
}

.icon {
  margin-right: 4px;
}
