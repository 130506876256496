@import '../../../../styles/shared';

.content {
  @include respond-to(tablet) {
    display: flex;
    flex-direction: column;
  }
}

.items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  @extend %scrollbar;
  &__prioritized::-webkit-scrollbar-thumb {
    background-color: rgba(var(--color-base-rgb), 0.6);
  }
  @include respond-to(tablet) {
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}

.title {
  min-height: unset;
  text-align: start;
  display: none;
  word-break: break-word;
  line-height: 32px;
  font-size: 24px;
  margin-bottom: 16px;

  color: var(--color-title-second);

  &__prioritized {
    color: var(--color-default);
  }

  @include respond-to(tablet) {
    display: inline;
  }
}
