.modal {
  & :global(.rc-dialog-close) {
    color: var(--color-default);

    opacity: 1;
  }
}

.content {
  width: 300px;
  min-height: 464px;

  padding: 40px;

  background-color: var(--color-default);
  background-image: url('../../../assets/modal-bg-bottom.png'),
    url('../../../assets/modal-bg-top.png');
  background-position: bottom left, 101% 0;
  background-repeat: no-repeat, no-repeat;

  & :global(.rc-dialog-close-x) {
    color: var(--color-default);
  }
}

.btn {
  width: 90px;
  height: 40px;
}

.title {
  color: var(--color-base-transparent-80);
  font-size: 16px;

  margin-bottom: 42px;
  max-width: 90%;
}

.iconWrapper {
  text-align: center;

  margin-bottom: 32px;
}

.description {
  font-size: 14px;
  line-height: 21px;
  color: var(--color-base-transparent-80);
  text-align: center;

  margin-bottom: 48px;
}

.status {
  text-align: center;
}

.statusText {
  color: var(--color-base-transparent-56);
}

.spinner__wrapper {
  display: flex;
  justify-content: center;
}
