@import '../../../../styles/shared';

.wrapper {
  position: relative;

  display: flex;
  align-items: center;

  padding: 0 16px 0 40px;
  overflow: hidden;

  border-radius: 24px;
  border: 1px solid var(--color-base-transparent-40);
  background-color: var(--color-default);

  &:hover {
    border-color: var(--color-actions);
  }

  &:focus-within {
    border-color: var(--color-primary);
  }

  transition: border-color $transition-base;
}

.reset__button {
  position: relative;
  width: 12px;
  height: 12px;
  opacity: 0.5;

  transition: opacity $transition-base;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 12px;
    border-left: 2px solid currentColor;
    transform: rotate(45deg);
  }

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 12px;
    border-left: 2px solid currentColor;
    transform: rotate(-45deg);
  }

  &:hover {
    opacity: 1;
  }
}

.input {
  padding: 12px 0;
  flex-grow: 1;

  font-size: 14px;

  color: var(--color-base);
  outline: none;
  border: none;
  -webkit-appearance: none;
}

.searchIcon {
  position: absolute;
  left: 15px;

  fill: var(--color-base);
}
