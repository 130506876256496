@import '../../../../styles/shared';

.main {
  position: relative;
  display: flex;
  height: calc(100% - 80px);

  outline: none;

  flex: 1 0 auto;
  padding: 6px 8px;
}

.btn__arrow {
  width: 56px;
  align-self: stretch;
  border-radius: 0;
  position: absolute;
  z-index: 100;
  left: 8px;
  height: calc(100% - 12px);
  &:last-of-type {
    left: calc(100% - 64px);
  }
  &:not(:disabled):hover {
    background: #566162;
    & * {
      stroke: #8798ad;
    }
  }
}

.image__wrapper {
  width: 100%;
  position: relative;

  flex-grow: 1;
  overflow: hidden;

  transform: translateZ(0);

  &::after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    background-color: var(--color-base);
  }
}
.fallback {
  background: var(--color-base);
}
.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
