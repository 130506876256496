@import '../../styles/shared';

.main {
  overflow: auto;
  display: flex;
  flex-direction: column;

  padding: 25px 40px;
  height: 100vh;
  flex-grow: 1;
  min-width: 780px;
}

.search__bar {
  margin-bottom: 20px;
}

.refunded__header {
  text-align: center;
  white-space: normal;
}

.table {
  flex-grow: 1;
  overflow: auto;
}

.tag {
  width: 8px;
  height: 8px;

  border-radius: 50%;
  background-color: var(--color-base-transparent-40);
  opacity: 0.4;

  &__exist {
    background-color: var(--color-primary);
    opacity: 1;
  }
}
