@import '../../../styles/shared';
@import '../../../styles/variables';

$header-height: 56px;
$content-height: calc(100vh - 56px);

.content {
  min-height: calc(100vh - #{$header-height});
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  background: var(--color-default);

  @include respond-to(tablet) {
    display: flex;
    flex-direction: column;
  }
  .col__menu__block {
    display: flex;
    overflow: auto;
    height: #{$content-height};

    @include respond-to(tablet) {
      height: calc(100vh - 305px);
      flex-direction: column;
      padding: 0;
    }
  }
  .col__calendar {
    height: $content-height;
    background: var(--background-base-transparent-48);
    overflow: auto;
    padding: 0;
    @extend %scrollbar;
    @include respond-to(tablet) {
      overflow: initial;
    }
  }

  .col__recommendation {
    min-height: #{$content-height};
    height: max-content;
    background: var(--background-base-transparent-100);
    margin-left: -12px;

    @include respond-to(tablet) {
      min-height: auto;
      height: max-content;
      flex: 0 0 auto;
      margin-left: 0;
      padding: 0;
    }
  }

  .col__menu {
    min-height: #{$content-height};
    height: max-content;
    display: flex;
    padding: 0;
    background: var(--color-default);

    @include respond-to(tablet) {
      flex: auto;
      height: 100%;
      min-height: auto;
    }
  }
}

.calendar__block {
  display: flex;
  flex-direction: column;
  padding: 0 28px;
  @include respond-to(tablet) {
    padding: 0 24px;
  }
}
.menu {
  display: flex;
  overflow: auto;
  height: 100%;
  @extend %scrollbar;
  @include respond-to(tablet) {
    width: 100%;
    flex-direction: column;
    overflow: unset;
  }
}
