@import '../../../styles/shared';
@import '../../../styles/variables';
@import '../shared';

.modal :global {
  .rc-dialog-body {
    position: relative;
    width: 450px;
    height: 680px;
    @include respond-to(mobile) {
      width: 100%;
    }
  }
}

.title {
  @extend %title;
}
.body {
  height: calc(100% - 140px);
  border-top: 1px solid var(--color-base-transparent-10);
  padding: 40px;
  overflow-y: auto;
  @extend %scrollbar;
}
.description {
  font-size: 16px;
  line-height: 24px;
  color: var(--color-base);
  margin-bottom: 51px;
  word-break: break-word;
  white-space: pre-wrap;
  &:nth-of-type(2) {
    margin-bottom: 40px;
  }
}
.form__controls {
  box-shadow: $box-shadow-line-top;
  border-top: 1px solid var(--color-base-transparent-10);

  padding: 24px 40px;
}

.btn {
  height: 40px;
  padding: 0 16px;

  &:not(:last-child) {
    margin-right: 16px;
  }
}
.form__row {
  display: flex;
  width: 100%;
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
  &__limits {
    position: relative;
    justify-content: space-between;
  }
  &__disabled:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0.24;
    background: var(--color-default);
  }
}

.field__amount {
  width: 120px;
  margin-right: 24px;
}

.field__defaultLimit {
  font-size: 16px;
  color: var(--color-base);
}
.field__otherLimits {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 32px;
  padding-bottom: 13px;
  border-bottom: 1px solid var(--color-base-transparent-10);
  & *:first-child {
    margin-bottom: 0;
  }
}
.field__confirmationMode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  margin-top: 32px;
  padding-top: 13px;
  border-top: 1px solid var(--color-base-transparent-10);
  .switchButton {
    min-width: 44px;
    margin-left: 20px;
  }
}
.field__limit {
  width: 120px;
}
.limit__error {
  top: 65px;
}

.price {
  color: var(--color-primary);
  font-size: 24px;
}
.row {
  margin-top: 24px;
}
.amount__description {
  width: 70%;
  font-size: 24px;
  line-height: 28px;
  color: var(--color-base);
}
.label {
  font-size: 14px;
  color: var(--color-actions);
}
.values {
  font-size: 14px;
  line-height: 21px;
  color: var(--color-base);
  margin-top: 8px;
  word-break: break-all;
  white-space: pre-wrap;
}
.categories {
  width: 100%;
  word-break: break-word;
  hyphens: auto;
  white-space: pre-wrap;
  text-align: justify;
  margin-top: 8px;
}
.frequency {
  font-size: 14px;
  color: var(--color-actions);
  margin-bottom: 19px;
}
.selectedLabel {
  background: #f08b31;
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 88px;
  width: 450px;
  height: 62px;
  background: #f08b31;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  color: var(--color-default);
  padding: 8px 40px;
}
