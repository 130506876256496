@import '../../../../styles/shared';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include respond-to(tablet) {
    display: none;
  }
}
.menu {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: var(--color-default);

  @include respond-to(tablet) {
    flex-direction: column;
  }
  &_mobile {
    display: none;
    @include respond-to(tablet) {
      display: flex;
    }
  }
}

.menu .menu__line {
  padding: 40px 12px 0 12px;

  @include respond-to(tablet) {
    flex: auto;
    padding-left: 24px;
    padding-top: 25px;
  }
}

.title {
  text-align: center;
  word-break: break-word;

  min-height: 64px;
  line-height: 32px;
  font-size: 24px;
  margin-bottom: 16px;

  color: var(--color-title-second);

  &__prioritized {
    color: var(--color-default);
  }

  @include respond-to(tablet) {
    min-height: unset;
    text-align: start;
  }
}

.line__prioritized {
  order: -1;

  background-color: var(--color-primary);
}
.noContent {
  height: auto;
  @include respond-to(tablet) {
    margin-top: 40px;
  }
}
