@import '../../../../../styles/shared';
@import '../../../../../styles/variables';

.item {
  position: relative;
  margin: 0 8px 8px 0;
}

.label {
  display: inline-block;

  color: var(--color-actions);
  font-size: 12px;

  border-radius: 4px;
  border: 1px solid var(--color-base-transparent-40);

  background-color: var(--color-default);
  cursor: pointer;

  padding: 5px 16px;
}

.checkbox {
  position: absolute;

  width: 1px;
  height: 1px;

  visibility: hidden;

  &:checked + .label {
    border: 1px solid transparent;
    background-color: var(--color-primary);

    color: var(--color-default);
  }
}
.form__group:not(:last-child) {
  margin-bottom: 24px;
}
