@import '../../styles/shared';

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  min-width: 900px;
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.body {
  flex-grow: 1;
  display: flex;
  height: calc(100vh - 162px);
  overflow: hidden;

  .steps__wrapper {
    width: 30%;
    padding: 20px 0 20px 100px;
  }

  .form__container {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;

    .step__title {
      color: var(--color-form-title);
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 40px;

      .step__icon {
        margin-left: 10px;
        vertical-align: middle;
        color: var(--color-form-title-icon);
      }
    }
  }
}

.form__group {
  margin-bottom: 40px;
}

.steps__list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .step__item {
    height: 44px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .step__border {
      width: 0;
      height: 100%;
      margin-right: -7px;

      .border__item {
        height: 50%;
        border-right: 2px solid #e7e8ea;

        &.unpassed__border {
          border-right-color: #e7e8ea;
        }

        &.passed__border {
          border-right-color: var(--color-primary-lite);
        }

        &.hidden__border {
          border-right-color: transparent;
        }
      }
    }

    .step__item_label {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;

      &:hover:not(.click__disabled) {
        cursor: pointer;
        .step__dot {
          background-color: var(--color-primary);
        }
        .step__label {
          color: #1e1e1e;
        }
      }

      &.click__disabled {
        .step__label {
          color: var(--color-actions-transparent-48);
        }

        &:hover {
          cursor: not-allowed;
        }
      }
    }

    .step__dot {
      width: 16px;
      min-width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: var(--color-primary);
    }

    &.step__passed {
      .step__dot {
        background-color: var(--color-primary-lite);
      }
    }

    .step__label {
      padding: 0 16px;
      font-size: 16px;
      min-width: 3em;
      text-align: left;
      color: var(--color-actions);
    }

    &.step__unpassed {
      .step__dot {
        background-color: #e7e8ea;
      }
    }

    &.step__active {
      .step__dot {
        width: 24px;
        min-width: 24px;
        height: 24px;
        background-color: var(--color-primary) !important;
        border: 4px solid var(--color-default);
        box-shadow: 0 4px 8px 0 var(--color-box-shadow);
        margin-left: -4px;
      }

      .step__label {
        padding: 0 12px;
        color: #1e1e1e !important;
      }

      .step__item_label:hover {
        cursor: unset;
      }
    }

    &.error {
      .step__dot {
        background-color: var(--color-error);
      }

      .step__label {
        color: var(--color-error-300);
      }
    }
  }
}

.controls {
  position: relative;
  display: flex;
  padding: 24px 40px 36px 40px;
  box-shadow: $box-shadow-line-top;
  justify-content: space-between;

  &__left,
  &__right {
    display: flex;
  }

  .btn {
    height: 40px;
    padding: 0 16px;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &.btn__next {
      display: flex;
      align-items: center;

      .btn__next_arrow {
        margin-left: 37px;
      }
    }
  }
}
