@import '../../../styles/shared';
@import '../shared';

.modal :global {
  .rc-dialog-body {
    width: 648px;
    height: 594px;
  }
}

.form__section {
  width: 320px;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.form__row:not(:last-child) {
  margin-bottom: 24px;
}

.row__duration {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .form__label {
    margin: 0;
  }
}

.form__label {
  @extend %label;
}

.form__value {
  @extend %text-ellipsis;

  color: var(--color-base);
  font-size: 16px;

  padding: 10px 0;
}

.form__title {
  @extend %title;
}

.form__body {
  padding: 0 40px;

  margin: auto 0;
}

.form__controls {
  box-shadow: $box-shadow-line-top;

  padding: 24px 40px;
}

.participants {
  width: 112px;
}

.btn {
  height: 40px;
  padding: 0 18px;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.datepicker {
  width: 100%;

  border: 1px solid var(--color-base-transparent-40);
  border-radius: 4px;
}
