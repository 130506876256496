@import '../../styles/shared';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  background-color: #fff;
}

.searchField {
  padding-bottom: 16px;
  border-bottom: 1px solid #e4e7ed;
}

.menuList {
  @extend %scrollbar;

  padding: 16px;
  overflow: auto;
}
