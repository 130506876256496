@import '../../styles/shared';

.layout {
  display: flex;

  min-height: 100vh;

  //noinspection CssInvalidPropertyValue
  @include respond-to(mobile) {
    // fixed problem with 100vh  for web browsers
    height: -webkit-fill-available;
  }
}

.layout__profile {
  flex-direction: column;
}
