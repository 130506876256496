.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn__select__mode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 12px 16px;

  font-size: 14px;

  & .btn__title {
    transform: translateX(0);
    transition: transform 1s;
    &_multi {
      transform: translateX(50px);
    }
  }
}

.icon__cross {
  font-size: 24px;
}
.btn__block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
}
.images__selected {
  font-size: 14px;
  color: var(--color-base);
  margin-top: 14px;
}
.btn__reset {
  font-size: 14px;
  color: var(--color-actions);
  border: none;
  padding: 0;
  margin-top: 14px;
  &:hover:enabled {
    background: transparent;
    color: var(--color-actions);
  }
  &:active {
    color: var(--color-actions);
    background: transparent;
    border: none;
  }
}
.btn__delete,
.btn__select__all {
  width: calc(50% - 8px);
  height: 40px;
  margin-bottom: 24px;
}
.icon__cancel {
  font-size: 18px;
}
.title__no__selecteted {
  font-size: 14px;
  color: var(--color-base-transparent-80);
  margin-top: 38px;
}
