@import '../../../styles/shared';
.container {
  width: calc(100% - 245px - 16px);
  display: flex;
  flex-direction: column;
}
.gallery {
  @extend %scrollbar;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-auto-rows: minmax(90px, max-content);
  grid-gap: 8px;
  align-content: start;

  flex-grow: 1;
  padding: 18px;
  overflow: auto;

  border: 1px solid var(--color-base-transparent-40);
  border-bottom: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.gallery:after {
  content: '';
  height: 10px;
}

.sample {
  position: relative;

  font-size: 0;
}

.image__wrapper {
  width: 100%;
  height: 100%;
}

.sample__checked,
.sample:hover {
  &::after {
    content: '';
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(#566162, 0.5);
  }

  .sample__checkbox,
  .icon__delete {
    opacity: 1;
  }
}

.image {
  width: 100%;
  height: 100%;

  cursor: pointer;
}

.sample__checkbox,
.icon__delete {
  position: absolute;
  top: 6px;
  right: 6px;

  opacity: 0;
  z-index: 99;

  cursor: pointer;

  color: var(--color-default);
  font-size: 24px;
}

.sample__checkbox {
  font-size: 20px;

  &__icon {
    width: 16px;
    height: 16px;
    border: 2px solid rgba(191, 197, 210, 0.6);
  }
}
