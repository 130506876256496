@import '../../styles/shared';

.content__tabs {
  @extend %admin-content--tabs;
}

%table-total-row {
  position: sticky;
  top: 0;
  z-index: 10;

  box-shadow: 0 8px var(--color-default);

  width: fit-content;
  min-width: 100%;

  min-height: 48px;

  display: flex;
  align-items: center;

  color: var(--color-default);
  background-color: var(--color-actions-darken);
  border-radius: 4px;
}
