.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-base-transparent-80);

  padding: 20px;
}
.icon {
  margin-bottom: 54px;
}
