@import '../../styles/shared';

.dropdown__menu {
  @extend %scrollbar;

  position: absolute;
  top: 50%;

  overflow: auto;
  max-height: 248px;
  width: 100%;

  z-index: 999;
  transform: translateY(-50%);

  border-radius: 4px;
  background-color: var(--color-default);
  box-shadow: 0 4px 8px 0 #00000029;
}

.dropdown__item {
  color: var(--color-base);
  font-size: 14px;
  line-height: 18px;

  padding: 12px 20px;

  &:hover {
    background-color: #e6e6e6;
  }
}
