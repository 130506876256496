@import '../../../../../styles/variables';

.allergen {
  display: inline-flex;
  align-items: center;

  position: relative;

  cursor: pointer;

  font-size: 14px;
  color: var(--color-actions);
  height: 40px;
  padding: 0 16px;

  svg {
    font-size: 8px;
    line-height: 0;

    margin-right: 12px;

    border: 1px solid var(--color-actions);
  }
}

.allergen input:checked {
  & ~ .title {
    color: var(--color-primary);
  }

  & ~ .icon {
    filter: grayscale(0);
  }
}

.icon {
  width: 16px;
  height: 16px;

  margin-right: 10px;

  object-fit: cover;
  object-position: center;

  filter: grayscale(1);
}
.allergens__list {
  display: flex;
  flex-wrap: wrap;
  max-width: 512px;
}
.allergen__item {
  width: 50%;
}
