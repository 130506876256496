@import '../../../styles/shared';

.modal :global {
  .rc-dialog-close {
    display: none;
  }

  .rc-dialog-body {
    position: relative;

    width: calc(100vw - 80px);
    height: 637px;

    & > *:first-child {
      width: 80%;
    }
  }
}

.column__first {
  display: flex;
  flex-direction: column;

  flex-shrink: 0;
  height: 100%;
}

.column__second {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
}

.panel__header {
  display: flex;
  align-items: center;

  height: 64px;
  padding: 24px;
}

.panel__title {
  font-size: 16px;
  color: var(--color-base-transparent-80);
}
.info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.field__wrap {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  &:not(:last-of-type) {
    margin-bottom: 40px;
  }
}
.label {
  font-size: 14px;
  line-height: 21px;
  color: var(--color-title-second);
  margin-bottom: 8px;
}
.text {
  font-size: 16px;
  line-height: 21px;
  color: var(--color-base);
}
