@import '../../../../styles/shared';

.body {
  width: 100%;
  height: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 24px 60px 40px 60px;

  @include respond-to(mobile) {
    padding: 24px 30px 40px 30px;
  }
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 16px;
  color: var(--color-base-transparent-80);
  padding: 19px 24px;
  border-bottom: 1px solid #e4e7ed;
}
.text {
  font-size: 16px;
  color: var(--color-base-transparent-80);
  text-align: center;
  margin-bottom: 24px;
}
.text__search {
  font-weight: 300;
  color: var(--color-base-transparent-80);
  text-align: center;
  margin-top: 24px;
}
.btn {
  padding: 12px 18px;
  margin-top: 49px;
}
