.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  background: var(--color-default);

  padding: 0 16px 16px 16px;
  border: 1px solid var(--color-base-transparent-40);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.pages {
  align-self: center;
}
.page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  border: 1px solid var(--color-actions);
  border-radius: 4px;
  padding-bottom: 12px;
  padding-top: 12px;
  margin-top: 16px;

  font: normal 14px/18px Rubik;
  color: var(--color-actions);
  &:not(:last-of-type) {
    margin-right: 11px;
  }
  &:focus {
    outline: none;
  }
}
.break {
  border: none;
  padding: 0;
  align-items: flex-end;
  font-weight: bold;
  letter-spacing: 4px;
}
.page__active {
  background: var(--color-primary);
  color: var(--color-default);
  border-color: transparent;
}
.page__link {
  width: 100%;
  text-align: center;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
.button {
  padding-left: 16px;
  padding-right: 16px;
}
.button__link {
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
