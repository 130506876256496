@import '../../../styles/shared';

.content {
  position: relative;
  display: grid;
  grid-auto-rows: 40px;
  margin-bottom: 20px;
}

.transaction__wrapper {
  display: grid;
  grid-template-columns: auto 70px 30px;
  align-items: center;
}

.header {
  color: var(--color-base);
  font-weight: 500;
}

.transaction__title {
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6px;
}

.amount__negative {
  color: var(--color-base);
}

.amount__positive {
  color: var(--color-primary);
}

.date {
  color: var(--color-base-transparent-80);
  font-weight: 300;
  font-size: 12px;
}

.btn__receipt {
  color: var(--color-actions-transparent-48);

  &:hover {
    color: var(--color-actions);
  }
}

.wrapper__id {
  display: grid;
  grid-template-columns: auto 100px;
  align-items: center;
}

.id {
  outline: none;
  border: none;

  width: auto;
}

.transaction__id {
  color: var(--color-base);
  font-size: 14px;
}

.btn__copy {
  width: 100%;
  font-size: 12px;
  text-align: start;
}
