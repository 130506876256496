.controls {
  position: relative;
}

.menu {
  position: fixed;

  z-index: 99;
  overflow: hidden;

  background-color: var(--color-default);
  border-radius: 4px;
  box-shadow: 0 4px 6px #00000029;
}

.icon {
  color: var(--color-actions);
  font-size: 24px;
  line-height: 0;
}
