@import '../../../../styles/shared';
@import '../../shared';

.select__section {
  display: flex;
  flex-direction: column;
  height: 100%;

  padding: 0 16px 18px 16px;
}

.select__title {
  padding: 18px 0;

  color: var(--color-base);
  font-size: 16px;

  box-shadow: $box-shadow-line-bottom;
}

.items {
  overflow: auto;
  flex-grow: 1;

  @extend %scrollbar;
}

.item {
  width: 100%;
  height: 40px;

  padding: 0 16px;

  @extend %text-default;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item__active {
  color: var(--color-default);

  background-color: var(--color-primary);
}

.item__name {
  @extend %text-ellipsis;
}

.item__code {
  opacity: 0.25;
}
