@import '../../../../styles/variables';

.order {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 40px;
}

.order__line {
  display: flex;
  justify-content: space-between;
  min-height: 30px;
  margin-bottom: 34px;
}

.order__number {
  margin-bottom: 4px;

  align-self: center;
  font-size: 14px;
  color: var(--color-title-first);
}

.order__info__line {
  font-size: 12px;
  line-height: 1.2;

  color: var(--color-actions);
  word-break: break-word;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
}

.order__items {
  font-size: 16px;
  line-height: 1.2;
  color: var(--color-base);
}

.order__item {
  margin-bottom: 18px;
}
