.modal :global(.rc-dialog-content) {
  width: 475px;
  padding: 40px;

  border-radius: 10px;
  border-style: solid;
  border-color: var(--color-green);
  border-width: 2px;

  overflow: hidden;
}

.text {
  margin-top: 25px;

  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: #232323;
}

.buttons {
  display: block;
  margin-top: 30px;
}

.btn {
  display: block;
  width: 100%;
  height: 70px;
  padding: 1em 2em;

  font-size: 14px;
  font-weight: bold;
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }
}
