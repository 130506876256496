.modal :global {
  .rc-dialog-content {
    padding: 24px 40px;
    overflow: visible;
  }
}

.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.form__row {
  grid-column: 1 / span 2;

  &:last-of-type {
    margin-top: 40px;
  }
}

.title {
  font-size: 16px;
  color: var(--color-base-transparent-80);

  margin-bottom: 48px;
}

.btn {
  padding: 12px 24px;

  &:not(:first-child) {
    margin-left: 16px;
  }
}

.model {
  background-color: transparent;
}
.roles {
  :global(.select) {
    &__option {
      font-size: 13px;
    }
  }
}
