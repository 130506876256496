.groups {
  position: relative;
  padding: 16px 0;
}

.label {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  color: var(--color-base-transparent-80);
  font-size: 16px;
}
