@import '../../../styles/shared';

$grid-column-template: repeat(3, 1fr);
$grid-gap: 8px;

@media print {
  #root {
    display: none;
  }
}

// refactoring
.modal :global {
  .rc-dialog-content {
    @extend %scrollbar;

    position: relative;

    border-radius: 8px 8px 0 0;

    width: 340px;
    overflow: visible;

    &::after {
      content: '';

      position: absolute;
      top: 100%;
      left: 0;

      width: 100%;
      height: 16px;
      background: linear-gradient(-45deg, transparent 16px, #fff 0),
        linear-gradient(45deg, transparent 16px, #fff 0);
      background-size: 16px 16px;
    }
  }

  .rc-dialog-header {
    display: none;
  }

  .rc-dialog-close {
    @media print {
      display: none;
    }
  }

  .rc-dialog-body {
    min-height: 200px;
  }
}

.print__wrapper {
  padding: 12px 8px;

  font-size: 14px;
  font-weight: 300;

  border: 1px solid transparent;

  @media print {
    border: 1px solid black;
  }

  &:global(.print) {
    border: 1px solid black;
  }
}

.title {
  margin-bottom: 28px;

  font-size: 12px;
}

.title__text {
  color: var(--color-base);
  font-size: 12px;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

.hr {
  margin: 8px 0;

  border-top: 2px dashed var(--color-base-transparent-64);
}

.grid__hr {
  @extend .hr;

  width: 100%;

  grid-column: 1 / span 3;
}

.data {
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: $grid-gap;

  margin-bottom: 12px;

  font-weight: 300;

  & :nth-child(even) {
    word-break: break-all;
  }
}

.items {
  font-weight: 300;
}

.items__header {
  display: grid;
  grid-template-columns: $grid-column-template;
  justify-items: center;
}

.items__item {
  display: grid;
  grid-template-columns: $grid-column-template;
  grid-gap: $grid-gap;
}

.item__title {
  grid-column: 1 / span 3;
}

.items__item {
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  & :nth-child(n + 2) {
    justify-self: center;
  }

  & :nth-child(5) {
    grid-column: 2 / 3;
  }
}

.receipt__prices,
.price__final {
  display: grid;
  grid-template-columns: $grid-column-template;
  grid-gap: $grid-gap;

  font-weight: 300;
}

.receipt__prices {
  & :nth-child(odd) {
    grid-column: 1 / 2;
  }

  & :nth-child(even) {
    grid-column: 3 / 4;
    margin: 0 22px;
    text-align: center;
  }
}

.receipt__tseData {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $grid-gap;

  & {
    word-break: break-all;
  }

  & :nth-child(even) {
    margin-right: 22px;
    text-align: right;
  }
}

.price__final :nth-child(2) {
  grid-column: 3 / 4;
  text-align: center;
}

.btn__wrapper {
  padding: 16px 8px;

  text-align: center;

  @media print {
    display: none;
  }
}

.btn__download {
  padding: 12px 16px;
  font-size: 14px;
}
