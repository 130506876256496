@import '../../../../styles/variables';

.order {
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
}

.order__line {
  cursor: pointer;
  display: grid;
  grid-template-columns: 40px 1fr 40px 40px;
  min-height: 30px;
  margin-bottom: 15px;
}

.btn__arrow {
  justify-self: flex-start;
  display: flex;
  align-items: flex-end;

  margin-bottom: -3px;
}

.order__number {
  margin-bottom: 4px;

  align-self: center;
  font-weight: 500;
  color: var(--color-base);
}

.order__info__line {
  font-size: 12px;
  color: var(--color-actions);
  word-break: break-word;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
}

.order__items {
  display: none;
  flex-direction: column;

  &__expanded {
    display: flex;
  }
}

.order__item {
  display: flex;
  padding: 12px 0;
  color: var(--color-base);
}

.item__amount {
  margin-right: 20px;
}

.btn__qr {
  font-size: 20px;
}

.icon {
  & * {
    fill: var(--color-actions) !important;
  }
  & path {
    opacity: 1;
  }
}
.icon__active {
  & * {
    fill: var(--color-primary) !important;
  }
}

.modal__info {
  line-height: 1.4;
}

.price {
  display: flex;
  align-items: center;
  color: var(--color-base);
  &__bottom {
    margin-top: 16px;
  }
}
