.svg {
  vertical-align: top;
}

.icon {
  line-height: 0;
}

:global(.icon-spin) {
  animation: loadingCircle 1s infinite linear;
}

@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}

.arrowTop {
  transform: rotate(180deg);
}

.arrowRight {
  transform: rotate(-90deg);
}

.arrowLeft {
  transform: rotate(90deg);
}
